.toolbarContainer {
  max-width: 240px;
  min-width: 240px;
  height: calc(100vh - 60px);
  z-index: 3;
  display: flex;
}

.leftColapseArrow {
  z-index: 10;
  margin-right: -20px;
  background-color: #c4c4c4;
  width: 25px;
  text-align: center;
  border-radius: 50%;
  color: #333;
  opacity: 0.6;
}

.rightColapseArrow {
  z-index: 10;
  margin-left: -20px;
  background-color: #c4c4c4;
  width: 25px;
  text-align: center;
  border-radius: 50%;
  color: #333;
  opacity: 0.6;
}

.leftColapseArrow:hover,
.rightColapseArrow:hover {
  opacity: 1;
  transform: scale(1.2);
  cursor: pointer;
}

.tabsListContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
