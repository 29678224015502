.workboardBackground {
  color: #fff;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  pointer-events: none;
  z-index: 0;
  transition: 0.2s;
}
