.feedbackButton {
  background-color: transparent;
  border-color: transparent;
  opacity: 0.8;
  position: relative;
  margin-right: 15px;
}

.feedbackButton:hover {
  background-color: transparent;
  border-color: transparent;
  opacity: 0.6;
}
