.appSettingsDialog {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.containerButtonS3Form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.containerButtonS3Form > :first-child {
  margin-right: 1rem;
}
