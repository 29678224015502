.firstRow {
  border-radius: 5px;
  margin-top: 3rem;
}

.title {
  font-weight: 200;
}

.appWrapper {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  padding-top: 0.7rem;
  border-radius: 5px;
}

.firstContainer {
  display: flex;
  width: 100%;
}

.appAction {
  display: flex;
  justify-content: end;
  align-items: center;
}

.appAction > button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  font-size: 1.1rem;
  margin-right: 0.5rem;
}

.downloadCodeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  font-size: 1.1rem;
  margin-right: 0.5rem;
  -webkit-box-shadow: 0px 0px 20px -3px rgba(13, 110, 253, 1);
  -moz-box-shadow: 0px 0px 20px -3px rgba(13, 110, 253, 1);
  box-shadow: 0px 0px 20px -3px rgba(13, 110, 253, 1);
}

.resumeWrapper {
  display: flex;
  justify-content: start;
  align-items: center;
}

.appDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0.5rem;
}

.appData {
  display: flex;
  justify-content: center;
  align-items: center;
}

.appDescription {
  overflow: hidden;
  max-width: 50rem;
}

.appDescription p {
  margin: 0.5rem 0 0 0;
  font-size: 2rem;
}

.appData {
  display: flex;
  justify-content: center;
  align-items: center;
}

.appData h3 {
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0 0 0 0.5rem;
}

.appData p {
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
  font-weight: 700;
}

.overFlowText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.appIcon {
  margin-right: 1rem;
  padding: 0.5rem;
}

@media only screen and (max-width: 480px) {
  .firstContainer {
    flex-direction: column;
  }
  .appAction {
    justify-content: flex-end;
  }
}
