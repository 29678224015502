.questionIcon {
  font-size: 0.75rem;
  position: relative;
  top: -6px;
  left: 4px;
  cursor: default;
}

.questionIcon:hover {
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
