:root {
  --placeholder-color: #6d6d7b;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  height: 100vh;
  width: 100vw;
  font-size: 16px;
  line-height: 1.5;
  z-index: 1;
}

.preview-body{
  /* background-color: red !important; */
  height: 100%;
  overflow: auto;
  /* min-height: 100vh */
  width: 100%;
  padding: 1rem 1.5rem 1rem 1.5rem;
  background-color: var(--bs-secondary-text-emphasis) !important;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  background: transparent;
}

#modal {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal:empty {
  display: none;
}

#modal > div {
  background-color: white;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  background: #f5f5f5;
  min-width: 40%;
  box-shadow: 0px 7px 17px 5px rgba(0, 0, 0, 0.1);
}

#modal .buttons button {
  display: inline-block;
  margin-right: 15px;
}

#modal .buttons {
  margin-top: 20px;
  float: left;
}

.btn-circle {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}

.btn-circle i {
  position: relative;
  top: -1px;
}

.btn-circle-sm {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 0.9rem;
}

.btn-circle-lg {
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 1.1rem;
}

.btn-circle-xl {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 1.3rem;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  /* necessario background transparente para evitar aparecer o scroll antes de atingir o max height */
  background: transparent;
  -webkit-border-radius: 1ex;
  height: 12px;
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: #8c8c8c;
  -webkit-border-radius: 1ex;
  /* -webkit-box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.75); */
}

::-webkit-scrollbar-thumb:hover {
  background: #f5f5f5;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.nav-tabs {
  overflow: auto hidden;
  flex-wrap: nowrap;
}


