/* Variables Component */

.varType {
  position: absolute;
  top: 16px;
  left: 0;
  font-size: 0.6rem;
  margin-bottom: 0;
}

.varIconButtons {
  color: #636161;
  cursor: pointer;
}

.varIconButtons:hover {
  color: #333;
  cursor: pointer;
}

.nativeVar {
  background-color: #e8e8e8;
}

.controlList {
  height: calc(100% - 143px);
  overflow-y: auto;
}

.ViewRoles {
  max-height: 125px;
  overflow: auto;
}
