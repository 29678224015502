.appSettingsDialog {
  margin-left: 1rem;
  margin-right: 1rem;
}

.dialogSaveButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.select {
  padding: 0.375rem 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  appearance: auto;
  border-radius: 0.375rem;
  width: 100%;
}
