input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.content {
  display: flex;
}

.colorHexa {
  text-align: start;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: fit-content;
}

.color {
  width: 40px;
  height: 30px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 0;
}

.contentIcons {
  width: fit-content;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0;
  margin-left: 9px;
}

.contentButtons {
  width: fit-content;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonContent {
  height: auto;
  background: transparent;
  border: none;
  padding: 0px 5px 0px 5px;
}
.buttonContent > i {
  font-size: 13px;
}

.buttonContent > span {
  font-size: 12px;
}

.cleanButton {
  font-size: 13px;
  border-radius: 3px;
  padding: 0px 5px 0px 5px;
  margin: 0px 2px 0px 2px;
}

.inputNumber {
  width: 50px;
  border: none;
  background: transparent;
}

.inputNumber:focus {
  outline: 0;
}

.label {
  white-space: nowrap;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invalidTextInput {
  border-color: red !important;
}

.invalidTextInput:focus {
  box-shadow: 0 1px 1px red inset, 0 0 8px red;
  outline: 0 none;
}

.errorMsg {
  color: #d9534f;
  font-size: 0.8rem;
}
