/* image container */
.imageStyle {
  width: 10rem;
  cursor: pointer;
  margin-bottom: 1rem;
  margin-right: 1.5rem;
}

.imageStyle:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 3px solid rgba(13, 110, 253, 0.4);
  background-color: rgba(128, 128, 128, 0.8);
}

.containerPhotos {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem;
  padding-bottom: 1rem;
  height: 23rem;
  margin-bottom: 1rem;
  overflow-y: auto;
}

.containerWithoutPhotos {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem;
  padding-bottom: 1rem;
  height: 20rem;
  margin-bottom: 1rem;
  overflow-y: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(128, 128, 128);
  font-weight: 600;
  font-size: larger;
}

/* upload container */
.containerUpload {
  height: 23rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border: 2px dashed rgba(128, 128, 128, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.textUpload {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}

.containerUpload:hover {
  border: 2px dashed rgba(128, 128, 128, 0.4);
  cursor: pointer;
}

.imageUploadButton {
  display: flex;
  justify-content: space-between;
}

.butonSaveDelete {
  margin-top: 1rem;
  width: 7rem;
}

.buttonForUpload {
  border: none;
  background-color: rgb(244, 244, 244);
  color: black;
  padding: 0.3rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}

.buttonForUpload:active {
  background-color: rgba(128, 128, 128, 0.8);
  color: white;
}

/* external link tab */

.containerExternalLink {
  height: 23rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.containerButtons {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.buttonsLinkContainer {
  width: 7rem;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}