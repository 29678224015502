.colorContainer {
  border-radius: 5px;
}

.colorPreview {
  display: flex;
  width: 100%;
  height: 8rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.colorPicker {
  width: 100%;
  height: 100%;
  border: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.colorProperty {
  padding: 0.5rem;
  border: 1px solid #dadada;
}

.colorProperty > input {
  border: 0;
  text-align: center;
  color: #a7a7a7;
  font-family: 'roboto';
  font-size: 0.9rem;
  font-weight: 600;
  width: 100%;
}

.colorKey {
  font-size: 1rem;
  font-family: 'roboto';
  color: #333;
}
