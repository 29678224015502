/* ---------- Action bar ---------- */

.ActionBarWrapper {
  position: absolute;
  width: 100%;
  height: 50px;
  z-index: 2;
  background-color: var(--bs-body-bg);
  padding: 6px 0 6px 0;
  padding-right: 66px;
  display: flex;
  justify-content: center;
}

.ActionsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.ActionNavItems {
  flex-wrap: nowrap !important;
}

.LeftSection {
  width: 300px;
  justify-content: flex-start;
  align-items: center;
}

.UnselectedButton:hover {
  filter: brightness(0.85);
}

.MiddleSection i {
  cursor: pointer;
  color: var(--bs-secondary-color);
}

.UnselectedIcon:hover {
  filter: brightness(0.85);
}

.UnsetButton:hover {
  filter: brightness(0.85);
}

.MiddleSection div:not(:last-child) {
  margin-right: 10px;
}

.RightSection {
  width: 300px;
  justify-content: flex-end;
  align-items: center;
}

.RightSection button:not(:last-child) {
  margin-right: 10px;
}

.Section {
  display: flex;
  flex-direction: row;
}

.ActionBarTabs i {
  padding-right: 0 !important;
  color: var(--bs-link-color);
}

.ActiveTab i {
  color: var(--bs-emphasis-color);
}

.HiddenTabName {
  display: hidden;
}

.HideTabNameBothSidebarsOpen,
.HideTabNameOneSidebarOpen,
.HideTabNameBothSidebarsClosed {
  display: none;
}

.showPopover {
  display: inline;
}

.hidePopover {
  display: none;
}

@media only screen and (min-width: 1620px) {
  .HideTabNameBothSidebarsOpen {
    display: inline;
  }

  .showPopover {
    display: none;
  }

  .hidePopover {
    display: inline;
  }
}

@media only screen and (min-width: 1350px) {
  .HideTabNameOneSidebarOpen {
    display: inline;
  }
}

@media only screen and (min-width: 1100px) {
  .HideTabNameBothSidebarsClosed {
    display: inline;
  }
}

/* ---------- Behavior tab ---------- */

.Wrapper {
  position: relative;
  overflow: hidden;
  margin: auto;
  top: 50px;
  padding: 16px;
  width: calc(100% - 480px);
  height: calc(100vh - 110px);
  background-color: rgba(var(--bs-tertiary-bg-rgb)) !important;
  padding-right: 0;
}

.BehaviorWrapper {
  height: 100%;
}

.Content {
  width: 100%;
  margin-top: 32px;
  overflow-y: auto;
  height: calc(100% - 76px);
  padding-right: 16px;
}

.FilterInput {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

.FilterInputForm {
  width: 300px;
  max-width: 400px;
  min-width: 100px;
}

/* ---------- Function instance ---------- */

.FunctionWrapper {
  cursor: pointer;
  border-radius: 5px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.FunctionWrapper:not(:last-child) {
  margin-bottom: 16px;
}

.FunctionWrapper:hover {
  background-color: rgba(77, 86, 95, 0.5) !important;
}


.FunctionDescription {
  font-family: SourceCodePro;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  line-height: 16px;
  width: 100%;
}

.Badge {
  font-family: SourceCodePro;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  width: 100%;
  font-size: 1rem;
  padding: 2px 16px;
  margin-right: 8px;
  border-radius: 5px;
  white-space: break-spaces;
  gap: 3px;
}

.BadgeBackground {
}

.itemIcons {
  background-color: #9fa6b2;
  border: 1px solid var(--bs-border-color);
  padding: 7px 10px;
  border-radius: 8px;
  cursor: pointer;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapperIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.CodeFunctionDefinition {
  white-space: pre;
  background-color: transparent;
}

.Details {
  color: #82c91e;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
}

.FunctionComment {
  display: flex;
  flex-direction: row;
  margin-left: 16px;
}

.CommentSlashes {
  color: #82c91e;
  margin-right: 6px;
  font-size: 12px;
}

/* ---------- Object instance ---------- */

.ObjectWrapper {
  cursor: pointer;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
}

.ObjectWrapper:not(:last-child) {
  margin-bottom: 16px;
}

.ObjectDescription {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 16px;
  width: 100%;
}

.ObjectLeftSideWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ObjectBadge {
  text-align: start;
  color: white;
  height: 28px;
  font-size: 12px;
  font-weight: 700;
  padding: 8px 16px;
  border-radius: 5px;
  white-space: break-spaces;
  width: 100%;
}

.ObjectBadgeBackground {
  background-color: #495057 !important;
}

.ObjectDetails {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
}

.containerObj {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--bs-border-color);
  cursor: pointer;
  margin-bottom: 1rem;
  height: fit-content;
  padding: 8px 16px;
  gap: 0.5rem;
}

.containerObj:hover {
  background-color: rgba(77, 86, 95, 0.5);
}

.headObj {
  width: 20%;
  text-align: center;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  font-weight: 600;
  color: var(--bs-emphasis-color);
  /* border-right: 1px solid var(--bs-border-color); */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bodyObj {
  width: 80%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.2rem;
  color: #919599;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footerObj {
  width: 20%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.2rem;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}

.footerTag {
  text-align: center;
  padding: 4px;
  border-radius: 999px;
  font-size: 10px;
  font-weight: bold;
  background-color: #2dc8ee;
  color: black;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ---------- VariableInstance ---------- */

.VariableWrapper {
  cursor: pointer;
  border-radius: 5px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.VariableWrapper:hover {
  background-color: rgba(77, 86, 95, 0.5);
}

.VariableWrapper:not(:last-child) {
  margin-bottom: 16px;
}

.VariableDescription {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 16px;
  width: 100%;
}

.VariableLeftSideWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.VariableBadge {
  text-align: start;
  color: white;
  height: 28px;
  font-size: 12px;
  font-weight: 700;
  padding: 8px 16px;
  border-radius: 5px;
  white-space: break-spaces;
  width: 100%;
  margin-right: 5px;
}

.VariableBadgeBackground {
  background-color: #495057 !important;
}

.VariableDetails {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
}

/* ---------- Common ---------- */

.TrashIconButtons {
  cursor: pointer;
}

.TrashIconButtons:hover {
  color: #636161;
  cursor: pointer;
}
