.selectedLayer {
  color: #6ea9ff;
  font-weight: 600;
}

.layerRow {
  cursor: pointer;
  color: #939393;
}

.layerRow:hover {
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.dropdownButton::after {
  display: none !important;
}

.hiddenChildren {
  display: none;
}

.childrenContainer {
  box-shadow: -1px 0 0 var(--bs-border-color);
}
