.tabsWrapper {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  background: #e0e0e0;
  padding: 8px 32px;
  opacity: 0.9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.searchListHeader {
  display: flex;
}

.searchVersionHeader {
  display: flex;
  align-items: center;
  gap: 8px;
}

.moduleList {
  min-height: 160px;
  height: 50vh;
  margin-top: 1rem;
  padding: 0.5rem;
  overflow: visible;
  overflow-y: auto;
}

.alignVertical {
  display: flex;
  align-items: center;
}

.moduleItem {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  padding-right: 16px;
  padding-left: 16px;
}

.moduleInfo {
  display: flex;
  gap: 8px;
}

ul {
  list-style-type: none;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
