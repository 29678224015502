.Check {
  padding: 0 0.75rem;
  position: relative;
  float: right;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: var(--bs-border-radius);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ParamTypeAndName {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 80%;
  grid-area: signature;
}

.paramGroup {
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  display: flex;
  justify-content: space-between;
}