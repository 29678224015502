.SubmitButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}

.FormSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 3px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.FormControlConfirm {
  height: 38px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  border-right: transparent;
}

.FormControlConfirmInvalid {
  height: 38px;
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e') !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  box-shadow: 0 0 0 0 transparent !important;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.FormControlNewPassword {
  height: 38px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  border-right: transparent;
}

.FormControl {
  max-width: 400px;
  width: 400px !important;
}

.inputGroup {
}

.message {
  color: #cc3344;
}

@media only screen and (max-width: 768px) {
  .message {
    width: 10rem !important;
  }

  .FormControl {
    max-width: 10rem !important;
  }

  .FormControlConfirm {
    max-width: 8rem !important;
  }

  .FormControlConfirmInvalid {
    max-width: 8rem !important;
  }

  .FormControlNewPassword {
    max-width: 12rem !important;
  }

  .inputGroup {
    max-width: 10rem !important;
  }
}
