.appSettingsDialog {
  margin-left: 1rem;
  margin-right: 1rem;
}

.dialogSaveButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dependencyHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
  align-items: self-end;
}

.dependencyList {
  max-height: 210px;
  overflow-y: auto;
}

.dependencyItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
}

.buttons {
  margin-bottom: 1rem;
}
