.bodyProperties {
  padding-left: 2.5rem;
  padding-top: 0;
  padding-right: 2.5rem;
}

.ButtonSpinner {
  margin-right: 10px;
}

.DisabledButtonSpinner {
  display: none;
}

.saveButton {
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.addPropButton {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
