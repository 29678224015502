.containerMain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerPage {
  height: inherit;
}

.title {
  font-weight: 200;
  margin-top: 2rem;
}

.containerSearchAndButton {
  display: flex;
  width: 100%;
  margin-top: 2rem;
}

.containerSearchAndButton > input {
  width: 14rem;
}

.containerSearchAndButton > button {
  margin-left: 1rem;
  background-color: inherit !important;
  color: #3b71ca;
}

.containerSearchAndButton > button:hover {
  color: white;
  background-color: #3b71ca !important;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}

.table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1.5rem;
}

.table > tbody > tr > th {
  padding: 0.9rem;
  border-bottom: 0.5px solid white;
}

.table > tbody > tr > td {
  padding: 0.9rem;
}

.table > tbody > tr:hover {
  /*impares*/
  background-color: #535a61;
  color: white;
}

.renderedTR {
  color: black;
}

.containerOpened {
  width: 100%;
  max-width: 79rem;
}

.containerMsgOpened {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.containerMsgOpened > p {
  padding: 0px;
  margin: 0px;
  width: 10.5%;
  display: flex;
  justify-content: flex-end;
}

.containerMsgOpened > span {
  width: 89.5%;
  display: flex;
  justify-content: flex-start;
  font-weight: 600;
  padding-left: 1rem;
}

.containerLogsOpened {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.containerLogsOpened > p {
  text-decoration: none;
  padding: 0px;
  margin: 0px;
  width: 10.5%;
  display: flex;
  justify-content: flex-end;
}

.containerLogsOpened > .containerLogs {
  width: 45.9%;
  border-radius: 3px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  background-color: inherit;
  overflow-y: auto;
  height: 5rem;
  max-height: 5rem;
  font-weight: 600;
  flex-wrap: wrap;
  margin-left: 1rem;
  min-width: 45.9%;
  max-width: 69.9%;
  resize: horizontal;
  /* flex: 3; */
  white-space: pre-line;
}

.containerButton {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  height: 5rem;
}

.containerButton > button {
  background-color: inherit !important;
  color: #3b71ca;
}

.containerButton > button:hover {
  color: white;
  background-color: #3b71ca !important;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}

.iconsWrapper {
  display: flex;
}

.containerIcons {
  cursor: pointer;
  margin-right: 1rem;
}

.fileName {
  padding: 0;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
}

.fileName:hover {
  color: #5cb85c;
}

.user {
  padding: 0;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}
