.FunctionSignature {
    display: flex;
}

.ReturnType {
    font-style: italic;
    color: #c792ea;
    margin-bottom: 0;
    margin-right: 0.5rem;
}

.FunctionName {
    font-style: italic;
    color: #dcdcaa;
    margin-bottom: 0;
    margin-right: 0.15rem;
}

.FunctionParameter {
    font-style: italic;
    color: #7fdbca;
    margin-bottom: 0;
}

.ParametersList {
    font-style: italic;
    color: #7fdbca;
    margin-bottom: 0;
    margin-right: 0.5rem;
 }