.dangerZoneContainer {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
}

.dangerZoneLabels {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.dangerZoneTitle {
  font-weight: 500;
}

.dangerZoneDescription {
  font-size: 0.8rem;
}

.deleteImgIcon {
  position: absolute;
  border-radius: 999px;
  padding-inline: 5px;
  top: 0px;
  right: 0px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.deleteImgIcon:hover {
  transform: scale(1.12);
}
