.errorMsg {
  color: #d9534f;
  font-weight: 600;
}

.switchGroupItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rowNameItem {
  width: 55%;
}

.rowSwitchItem {
  width: 15%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}