.InputDarkThemeVariables {
  --select-background: rgb(3, 40, 48);
  --select-foreground: rgb(13, 110, 253);

  --placeholder-color: rgba(122, 117, 117, 0.757);
}

.InputLightThemeVariables {
  --select-background: white;
  --select-foreground: #111827;

  --placeholder-color: rgba(122, 117, 117, 0.757);
}

.ActionInput {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 2.5rem;
  border-style: none;
  width: 10rem;
  min-width: 8rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.25rem;
  color: var(--select-foreground);
  background-color: var(--select-background);
  border-radius: 0.375rem;
}

.ActionInputL:active,
.ActionInput:focus {
  outline: none;
}

.ActionInput::placeholder {
  color: var(--placeholder-color);
}

.ActionInputLabel {
  margin-bottom: 0px;
  text-align: center;
  font-size: 0.9rem;
}

.Options {
  position: absolute;
  z-index: 10;
  overflow: auto;
  max-height: 150px;
  width: fit-content;
  padding-left: 0;
}

.Option {
  position: relative;
  opacity: 1;
  background: var(--bs-info-bg-subtle);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
