.PageWrap {
  background-color: #343a40;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.LoginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% / 2.5);
  min-width: 690px;
  padding-top: 50px;
}

.LoginLogoContainer {
  width: 480px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.LoginExocodeLogo {
  padding-bottom: 1rem;
}

.LoginFormCard {
  width: 100%;
  border-radius: 10px;
  padding-left: 7rem;
  padding-right: 7rem;
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: #2b3036 !important;
}

.LoginExternalLink {
  color: #007bff;
  font-size: 0.875em;
}

.LoginExternalLinkCenter {
  text-align: center;
}

.SubmitButton {
  width: 100%;
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .LoginLogoContainer {
    width: 300px;
  }
}

@media (max-width: 690px) {
  .LoginContainer{
    min-width: unset !important;
    width: 100%;
  }

  .LoginLogoContainer {
    width: 280px;
  }

  .LoginFormCard {
    width: 100%;
    border-radius: 10px;
    background-color: #2b3036 !important;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media (max-width: 480px) {
  .LoginFormCard {
    width: 98%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}