.desktop {
  min-width: 1024px;
  min-height: 576px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.tablet {
  min-width: 624px;
  min-height: 766px;
  aspect-ratio: 3/4;
  height: 99%;
  margin: auto;
}

.mobile {
  min-width: 375px;
  min-height: 667px;
  aspect-ratio: 9/16;
  height: 99%;
  margin: auto;
}

.urlBar {
  height: 25px;
  border-radius: 3px;
  background: white;
  flex-grow: 1;
  margin-right: 2%;
  padding: 0 10px 0 10px;
  font-family: monospace;
  color: #889396;
  overflow: hidden;
  user-select: none;
  font-size: 1.2rem;
}

/* // */

.browserControls {
  height: 50px;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #e6ecef;
  color: #bec4c6;
}

.windowControls {
  flex: 0 0 60px;
  margin: -2px 2%;
}

.windowControls span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50px;
}

.close {
  background: #ff8585;
}

.minimize {
  background: #ffd071;
}

.maximise {
  background: #74ed94;
}

.loading {
  position: absolute;
  left: calc(50% - 8.5px);
  top: 50%;
}
