.table {
  width: 100%;
  min-width: 800px;
}

.ContentWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 6px;
}

.SubscriptionDescription {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 24px;
}

.ManageSubscription {
  margin-left: auto;
}

/* PricingTable */
.PricingCard {
  position: relative;
  background-color: transparent;
  /* min-width: 375px; */
  min-height: 574px;
  max-height: 1200px;
  padding: 24px;
  border-radius: 5px;
  border: 1px solid var(--bs-border-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.PricingCard:hover {
  transform: scale(1.01);
  transition: ease-in-out 0.1s;
}

.freePlan {
  border-color: #ffbd70 !important;
  -webkit-box-shadow: 0px 0px 18px 0px rgba(255, 189, 112, 0.54);
  -moz-box-shadow: 0px 0px 18px 0px rgba(255, 189, 112, 0.54);
  box-shadow: 0px 0px 18px 0px rgba(255, 189, 112, 0.54);
}

.individualPlan {
  border-color: #a7d5ff !important;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(167, 213, 255, 1);
  -moz-box-shadow: 0px 0px 16px 0px rgba(167, 213, 255, 1);
  box-shadow: 0px 0px 16px 0px rgba(167, 213, 255, 1);
}

.organizationPlan {
  border-color: #cf93ff !important;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(207, 147, 255, 1);
  -moz-box-shadow: 0px 0px 16px 0px rgba(207, 147, 255, 1);
  box-shadow: 0px 0px 16px 0px rgba(207, 147, 255, 1);
}

.selectedCard {
  border-width: 5px;
}

.freePlanTitle {
  color: #ffbd70;
}

.individualPlanTitle {
  color: #a7d5ff;
}

.organizationPlanTitle {
  color: #cf93ff;
}

.freePlanButton {
  border-color: #ffbd70 !important;
  background-color: #ffbd70 !important;
}

.selectedButton .freePlanButton:hover {
  background-color: #ffbd70 !important;
}

.individualPlanButton {
  border-color: #a7d5ff !important;
  background-color: #a7d5ff !important;
}

.organizationPlanButton {
  border-color: #cf93ff !important;
  background-color: #cf93ff !important;
}

.organizationPlanButton .PricingCard:hover {
  cursor: pointer;
  opacity: 0.5;
}

.PricingCard > *:not(:last-child) {
  margin-bottom: 32px;
}

.PricingTitle h4 {
  padding-bottom: 12px;
  font-size: 24px;
  font-weight: 800;
}

.Price {
  font-size: 30px;
  font-weight: 600;
  align-items: center;
}

.Price .Recurrence {
  font-size: 16px;
  font-weight: normal;
}

.Features li {
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
}

.Features li i {
  font-size: 16px;
  text-align: left;
  padding-right: 30px;
}

.PricingCard .SelectBtn {
  width: 100%;
  font-size: 16px;
}

.PricingCard .freePlanButton:hover {
  background-color: #ffbd70 !important;
  border-color: #ffbd70 !important;
}

.PricingCard .individualPlanButton:hover {
  background-color: #a7d5ff !important;
  border-color: #a7d5ff !important;
}

.PricingCard .organizationPlanButton:hover {
  background-color: #cf93ff !important;
  border-color: #cf93ff !important;
}

.FormButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
}

.header_Cell {
  height: 100%;
  vertical-align: top;
  padding-bottom: 32px !important;
}

.nonSubscribedColumn {
  width: 14%;
}

.header_CellContent {
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header_CellTitle {
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.header_CellButton {
  margin-top: 16px;
  width: 100%;
}

.cell_Feature {
  padding: 16px;
  height: 100%;
  font-weight: bold;
  position: relative;
}

.cell_FeatureValue {
  padding: 16px;
  text-align: center;
}

.checkmarkIcon {
  color: #c0ffbf;
}

.timesIcon {
  color: #ffa8a8;
}

.cell_FooterValue {
  padding-top: 32px !important;
  font-size: 1.25rem;
  text-align: center;
  padding: 16px;
  font-weight: bold;
}

.cell_Emphasis {
  font-size: 1.3rem;
}

.cell_Emphasis button {
  font-size: 1.1rem;
  width: 20%;
}

.cell_Emphasis .header_CellTitle {
  font-size: 1.5rem;
}

.cell_Emphasis .Pricing {
  font-size: 1.5rem;
}

.Price {
  font-size: 1.6rem;
  font-weight: bold;
}

.PriceDescription1 {
  font-weight: normal;
  font-size: 1rem;
}

.PriceDescription2 {
  font-weight: normal;
  font-size: 1rem;
  color: rgba(255, 147, 178, 1);
}

.cell_FeatureValueInner {
  height: 100%;
}

.free_plan_title {
  color: rgba(255, 189, 112, 0.54);
}
.basic_plan_title {
  color: rgba(167, 213, 255, 1);
}
.plus_plan_title {
  color: rgba(207, 147, 255, 1);
}
.professional_plan_title {
  color: rgba(255, 147, 178, 1);
}
.enterprise_plan_title {
  color: rgba(255, 188, 147, 1);
}

.free_plan_column_left {
  box-shadow: inset 8px 0 6px -8px rgba(255, 189, 112, 0.54);
}
.free_plan_column_right {
  box-shadow: inset -8px 0px 6px -8px rgba(255, 189, 112, 0.54);
}
.free_plan_column {
  box-shadow: inset 8px 0 6px -8px rgba(255, 189, 112, 0.54),
    inset -8px 0px 6px -8px rgba(255, 189, 112, 0.54);
}

.basic_plan_column_left {
  box-shadow: inset 8px 0 6px -8px rgba(167, 213, 255, 1);
}
.basic_plan_column_right {
  box-shadow: inset -8px 0px 6px -8px rgba(167, 213, 255, 1);
}
.basic_plan_column {
  box-shadow: inset 8px 0 6px -8px rgba(167, 213, 255, 1),
    inset -8px 0px 6px -8px rgba(167, 213, 255, 1);
}

.plus_plan_column_left {
  box-shadow: inset 8px 0 6px -8px rgba(207, 147, 255, 1);
}
.plus_plan_column_right {
  box-shadow: inset -8px 0px 6px -8px rgba(207, 147, 255, 1);
}
.plus_plan_column {
  box-shadow: inset 8px 0 6px -8px rgba(207, 147, 255, 1),
    inset -8px 0px 6px -8px rgba(207, 147, 255, 1);
}

.professional_plan_column_left {
  box-shadow: inset 8px 0 6px -8px rgba(255, 147, 178, 1);
}
.professional_plan_column_right {
  box-shadow: inset -8px 0px 6px -8px rgba(255, 147, 178, 1);
}
.professional_plan_column {
  box-shadow: inset 8px 0 6px -8px rgba(255, 147, 178, 1),
    inset -8px 0px 6px -8px rgba(255, 147, 178, 1);
}

.enterprise_plan_column_left {
  box-shadow: inset 8px 0 6px -8px rgba(255, 188, 147, 1);
}
.enterprise_plan_column_right {
  box-shadow: inset -8px 0px 6px -8px rgba(255, 188, 147, 1);
}
.enterprise_plan_column {
  box-shadow: inset 8px 0 6px -8px rgba(255, 188, 147, 1),
    inset -8px 0px 6px -8px rgba(255, 188, 147, 1);
}

.free_plan_column_header_left {
  box-shadow: inset 8px 0 6px -8px rgba(255, 189, 112, 0.54),
    inset 0px 8px 6px -8px rgba(255, 189, 112, 0.54);
}
.free_plan_column_header_right {
  box-shadow: inset -8px 0px 6px -8px rgba(255, 189, 112, 0.54),
    inset 0px 8px 6px -8px rgba(255, 189, 112, 0.54);
}
.free_plan_column_header {
  box-shadow: inset 8px 0 6px -8px rgba(255, 189, 112, 0.54),
    inset -8px 0px 6px -8px rgba(255, 189, 112, 0.54),
    inset 0px 8px 6px -8px rgba(255, 189, 112, 0.54);
}

.basic_plan_column_header_left {
  box-shadow: inset 8px 0 6px -8px rgba(167, 213, 255, 1),
    inset 0px 8px 6px -8px rgba(167, 213, 255, 1);
}
.basic_plan_column_header_right {
  box-shadow: inset -8px 0px 6px -8px rgba(167, 213, 255, 1),
    inset 0px 8px 6px -8px rgba(167, 213, 255, 1);
}
.basic_plan_column_header {
  box-shadow: inset 8px 0 6px -8px rgba(167, 213, 255, 1),
    inset -8px 0px 6px -8px rgba(167, 213, 255, 1), inset 0px 8px 6px -8px rgba(167, 213, 255, 1);
}

.plus_plan_column_header_left {
  box-shadow: inset 8px 0 6px -8px rgba(207, 147, 255, 1),
    inset 0px 8px 6px -8px rgba(207, 147, 255, 1);
}
.plus_plan_column_header_right {
  box-shadow: inset -8px 0px 6px -8px rgba(207, 147, 255, 1),
    inset 0px 8px 6px -8px rgba(207, 147, 255, 1);
}
.plus_plan_column_header {
  box-shadow: inset 8px 0 6px -8px rgba(207, 147, 255, 1),
    inset -8px 0px 6px -8px rgba(207, 147, 255, 1), inset 0px 8px 6px -8px rgba(207, 147, 255, 1);
}

.professional_plan_column_header_left {
  box-shadow: inset 8px 0 6px -8px rgba(255, 147, 178, 1),
    inset 0px 8px 6px -8px rgba(255, 147, 178, 1);
}
.professional_plan_column_header_right {
  box-shadow: inset -8px 0px 6px -8px rgba(255, 147, 178, 1),
    inset 0px 8px 6px -8px rgba(255, 147, 178, 1);
}
.professional_plan_column_header {
  box-shadow: inset 8px 0 6px -8px rgba(255, 147, 178, 1),
    inset -8px 0px 6px -8px rgba(255, 147, 178, 1), inset 0px 8px 6px -8px rgba(255, 147, 178, 1);
}

.enterprise_plan_column_header_left {
  box-shadow: inset 8px 0 6px -8px rgba(255, 188, 147, 1),
    inset 0px 8px 6px -8px rgba(255, 188, 147, 1);
}
.enterprise_plan_column_header_right {
  box-shadow: inset -8px 0px 6px -8px rgba(255, 188, 147, 1),
    inset 0px 8px 6px -8px rgba(255, 188, 147, 1);
}
.enterprise_plan_column_header {
  box-shadow: inset 8px 0 6px -8px rgba(255, 188, 147, 1),
    inset -8px 0px 6px -8px rgba(255, 188, 147, 1), inset 0px 8px 6px -8px rgba(255, 188, 147, 1);
}

.free_plan_column_footer_left {
  box-shadow: inset 8px 0 6px -8px rgba(255, 189, 112, 0.54),
    inset 0px -8px 6px -8px rgba(255, 189, 112, 0.54);
}
.free_plan_column_footer_right {
  box-shadow: inset -8px 0px 6px -8px rgba(255, 189, 112, 0.54),
    inset 0px -8px 6px -8px rgba(255, 189, 112, 0.54);
}
.free_plan_column_footer {
  box-shadow: inset 8px 0 6px -8px rgba(255, 189, 112, 0.54),
    inset -8px 0px 6px -8px rgba(255, 189, 112, 0.54),
    inset 0px -8px 6px -8px rgba(255, 189, 112, 0.54);
}

.basic_plan_column_footer_left {
  box-shadow: inset 8px 0 6px -8px rgba(167, 213, 255, 1),
    inset 0px -8px 6px -8px rgba(167, 213, 255, 1);
}
.basic_plan_column_footer_right {
  box-shadow: inset -8px 0px 6px -8px rgba(167, 213, 255, 1),
    inset 0px -8px 6px -8px rgba(167, 213, 255, 1);
}
.basic_plan_column_footer {
  box-shadow: inset 8px 0 6px -8px rgba(167, 213, 255, 1),
    inset -8px 0px 6px -8px rgba(167, 213, 255, 1), inset 0px -8px 6px -8px rgba(167, 213, 255, 1);
}

.plus_plan_column_footer_left {
  box-shadow: inset 8px 0 6px -8px rgba(207, 147, 255, 1),
    inset 0px -8px 6px -8px rgba(207, 147, 255, 1);
}
.plus_plan_column_footer_right {
  box-shadow: inset -8px 0px 6px -8px rgba(207, 147, 255, 1),
    inset 0px -8px 6px -8px rgba(207, 147, 255, 1);
}
.plus_plan_column_footer {
  box-shadow: inset 8px 0 6px -8px rgba(207, 147, 255, 1),
    inset -8px 0px 6px -8px rgba(207, 147, 255, 1), inset 0px -8px 6px -8px rgba(207, 147, 255, 1);
}

.professional_plan_column_footer_left {
  box-shadow: inset 8px 0 6px -8px rgba(255, 147, 178, 1),
    inset 0px -8px 6px -8px rgba(255, 147, 178, 1);
}
.professional_plan_column_footer_right {
  box-shadow: inset -8px 0px 6px -8px rgba(255, 147, 178, 1),
    inset 0px -8px 6px -8px rgba(255, 147, 178, 1);
}
.professional_plan_column_footer {
  box-shadow: inset 8px 0 6px -8px rgba(255, 147, 178, 1),
    inset -8px 0px 6px -8px rgba(255, 147, 178, 1), inset 0px -8px 6px -8px rgba(255, 147, 178, 1);
}

.enterprise_plan_column_footer_left {
  box-shadow: inset 8px 0 6px -8px rgba(255, 188, 147, 1),
    inset 0px -8px 6px -8px rgba(255, 188, 147, 1);
}
.enterprise_plan_column_footer_right {
  box-shadow: inset -8px 0px 6px -8px rgba(255, 188, 147, 1),
    inset 0px -8px 6px -8px rgba(255, 188, 147, 1);
}
.enterprise_plan_column_footer {
  box-shadow: inset 8px 0 6px -8px rgba(255, 188, 147, 1),
    inset -8px 0px 6px -8px rgba(255, 188, 147, 1), inset 0px -8px 6px -8px rgba(255, 188, 147, 1);
}

.tooltip {
  height: 100%;
  width: 100%;
}

.soon {
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px 5px;
  border-radius: 99px;
  font-size: small;
  background-color: rgba(52, 58, 64, 1);
  cursor: pointer;
}
