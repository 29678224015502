.appSettingsDialog {
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
}

.dialogSaveButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.containerModal {
}

@media only screen and (max-width: 480px) {
  .containerModal {
    width: 380px;
    height: 550px;
  }
}
