/* ---------- Header ---------- */
.FunctionsGroupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
}

.HeaderTitle {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 400;
  width: 250px;
}

.FunctionsGroupButtonArrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 250px;
}

.EditFunctionButton {
  white-space: nowrap;
  margin-right: 16px;
}

.NewFunctionButton {
  white-space: nowrap;
  margin-right: 2.5rem;
}

.FixedIconWidth {
  font-size: 0.7rem;
  min-width: 18px;
}

.FunctionsListWrapper {
  margin-top: 8px;
  margin-bottom: 32px;
}

.footerTag {
  text-align: center;
  padding: 4px;
  border-radius: 5px;
  font-size: 11px;
  font-weight: bolder;
  background-color: #2dc8ee;
  color: black;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
}

/* ---------- Function ---------- */
.FunctionWrapper {
  border-radius: 5px;
  padding: 8px;
}

.FunctionWrapper:not(:last-child) {
  margin-bottom: 16px;
}

.FunctionDescription {
  font-family: SourceCodePro;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 16px;
}

.PagesDescription {
  font-family: SourceCodePro;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 16px;
}

.PagesWrapper {
  border-radius: 5px;
  padding: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.Badge {
  font-family: SourceCodePro;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  width: 100%;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 5px;
  white-space: break-spaces;
}

.BadgeFunction {
  text-align: center;
  padding: 3px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
  background-color: #2dc8ee;
  color: black;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.BadgeNew {
  text-align: center;
  padding: 3px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
  background-color: #ffc107;
  color: black;
  width: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapperIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
}

.itemIcons {
  padding: 3px 10px;
  border-radius: 8px;
  cursor: pointer;
  /* height: 45px; */
  /* border: 1px solid red; */
}

.iconActive {
  background-color: #9fa6b2 !important;
}

.BadgeBackground {
  /* background-color: #495057 !important; */
  /* background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; */
  background-color: #1c2025 !important;
}

.CodeFunctionDefinition {
  white-space: pre;
  background-color: transparent;
}

.Details {
  color: #82c91e;
  font-size: 12px;
  font-weight: 600;
  /* margin-bottom: 10px; */
}

.FunctionComment {
  display: flex;
  flex-direction: row;
  margin-left: 16px;
}

.CommentSlashes {
  color: #82c91e;
  margin-right: 6px;
  font-size: 12px;
}

.TypeSelect {
  color: blueviolet;
  width: min-content;
}

.FunctionEditorWrap {
  display: flex;
  justify-content: space-evenly;
}

.FunctionEditorWrapLeft {
  display: flex;
  flex-direction: column;
}

.FunctionEditorWrapRight {
  display: flex;
  justify-content: space-evenly;
}

.FunctionEditorFlags {
  display: flex;
  flex-direction: row;
}

.FunctionNameEditor {
  width: 50%;
}

.FunctionTypeEditorWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.wrapMethodAndPath {
  display: flex;
  align-items: center;
  padding: 0.7rem;
}

.containerPathDesc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: bold;
}

.PostBackgroundColor {
  background-color: #198754 !important;
}

.GetBackgroundColor {
  background-color: #0dcaf0 !important;
}

.PutBackgroundColor {
  background-color: #ffc107 !important;
}

.DeleteBackgroundColor {
  background-color: #dc3545 !important;
}

.PatchBackgroundColor {
  background-color: #ba38e8 !important;
}

.functionNameStyle {
  color: #cfcc9b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
  margin-right: 4px;
}
