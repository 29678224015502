.avatar {
  font-weight: 500;
  color: white;
  background-color: #479f76;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  font-size: 16px;
}

.container {
  display: flex;
  align-items: center;
  border: 1px solid #383d46;
  padding: 15px;
  background-color: #25272e;
  justify-content: space-between;
}

.containerIconName {
  display: flex;
  align-items: center;
}

.containerIcon {
  cursor: pointer;
  padding-inline: 17px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 8px;
  background-color: #383d46;
}

.name {
  color: #537cc6;
  margin-left: 20px;
  font-weight: 600;
}
