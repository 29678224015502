.framename {
  position: absolute;
  top: -27px;
  right: 10px;
  color: #ececec;
  font-weight: bold;
}

.relationshipConnector {
  position: absolute;
  width: 50px;
  height: 50px;
  color: aquamarine;
  border-radius: 50%;
}

.tableFrame {
  position: relative;
  background-color: #fafafa;
  min-width: 200px;
  -webkit-box-shadow: 0px 7px 15px -2px rgba(111, 111, 111, 0.57);
  box-shadow: 0px 7px 15px -2px rgba(111, 111, 111, 0.57);
  transition: 0.15s ease-in-out all;
  animation: shake 0.2s;
  animation-iteration-count: initial;
  z-index: 2;
}

.tableFrameContainer {
  position: relative;
  z-index: 2;
}

.selectedTable {
  border: 5px solid #0d6efd;
  border-radius: 5px;
}

.selectedTable .tableFrame {
  box-shadow: 0px 7px 15px 5px rgba(13, 110, 253, 0.57);
}

.manyToOnePoint {
  left: -15px;
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 1;
  background-color: orange;
  top: 0px;
  border-radius: 50px 0px 0px 50px;
  z-index: 1;
  transition: cubic-bezier(1, 0, 0, 3.18) 0.1s;
}

.manyToOnePoint:hover {
  width: 35px;
  left: -25px;
}

.oneToManyPoint {
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 1;
  /* background-color: aqua; */
  border-radius: 50px 50px 50px 50px;
  z-index: 1;
  transition: cubic-bezier(1, 0, 0, 3.18) 0.1s;
}

.oneToManyPoint:hover {
  transform: scale(1.5);
}

@keyframes shake {
  0% {
    transform: rotate(0deg) translateX(3px);
    opacity: 0%;
  }

  25% {
    transform: rotate(-2deg) translateX(0px);
    opacity: 25%;
  }

  50% {
    transform: rotate(0deg) translateX(-3px);
    opacity: 50%;
  }

  75% {
    transform: rotate(2deg) translateX(0px);
    opacity: 75%;
  }

  100% {
    transform: rotate(0deg) translateX(0px);
    opacity: 100%;
  }
}

.tableTitle {
  user-select: none;
}

.tableTitle th {
  padding: 0.7rem;
}

.tableRow td {
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.tableRow td:last-child {
  padding-bottom: 5px;
}

.newTableRow td {
  padding-left: 0.5rem;
}

.iconMoreColumn {
  position: sticky;
  bottom: -7px;
  color: #777;
  text-align: center;
  width: 100%;
  margin: 0;
}

table,
tr,
td {
  border: none;
}

.disabledTable {
  pointer-events: none;
  opacity: 0.5;
}
