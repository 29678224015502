.ColumnsListWrapper {
  width: 100%;
  max-height: 360px;
  font-size: 0.875rem;
  cursor: grab;
  z-index: 0;
}

.ColumnWrapper {
  padding: 4px 0px;
  margin: 0;
}

.EmptyColumnWrapper {
  padding: 4px 0px;
  margin: 0;
  cursor: default;
}

.TableName {
  width: 90%;
}

.SmallFont {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.KeyIcon {
  color: yellow;
}

.ClickableText:hover {
  cursor: pointer;
}

.ColumnButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ColumnButton {
  margin: 0 8px;
}

.ColumnReorderButton:hover {
  cursor: grab;
}

.button {
  padding: 0.1rem 0.5rem !important;
  font-size: medium;
  height: 2rem;
}

.icons {
  padding: 0px;
  height: 1rem;
}
