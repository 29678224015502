.editBody::-webkit-scrollbar {
    width: 8px;
}

.editBody::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
}

.editBody::-webkit-scrollbar-thumb:hover {
    background-color: #777;
}

.editBody::-webkit-scrollbar-track {
    background: transparent;
}  