.ParametersWrapper {
  border: 1px solid var(--bs-border-color);
  border-radius: 4px;
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;

  /* min-height: 200px; */
  max-height: 300px;
  gap: 0.5rem;
}

.ParameterItemWrapper {
  display: flex;
  gap: 0;
  align-items: center;
  width: 100%;
}

.ParameterItemWrapper select,
.ParameterItemWrapper input {
  border: 1px solid var(--bs-border-color);
  border-radius: 0;
  box-sizing: border-box;
  margin: 0;
}

.inputWidthSmall {
  width: 6%;
}

.inputWidthMedium {
  width: 10%;
}

.inputWidthLarge {
  width: 22%;
}

.inputWidthLargeLarge {
  width: 28%;
}

.halfWidthInput {
  flex: 1;
}

.rightRadius {
  border-radius: 0px 8px 8px 0px !important;
}

.leftRadius {
  border-radius: 8px 0px 0px 8px !important;
}

.addButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
