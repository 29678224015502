.EditorPanelRoot {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 4fr;
  grid-template-areas: 'signature signature' 'parameters options';
}

.ReturnTypeAndName {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 50%;
  padding-right: 1rem;
  grid-area: signature;
}

.ReturnType {
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  display: flex;
  justify-content: space-between;
}

.ReturnTypeListCheck {
  padding: 0 0.75rem;
  position: relative;
  float: right;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: var(--bs-border-radius);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  border-left: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.deleteButtonContainerDisabled {
  cursor: default;
  background-color: #e397a4 !important;
  padding-right: 9px;
  padding-left: 9px;
}

.deleteButtonContainer {
  background-color: #dc4c64 !important;
  padding-right: 9px;
  padding-left: 9px;
}

.methodEndpoint {
  border-left: 'none';
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Parameters {
  width: 100%;
  grid-area: parameters;
  display: flex;
  flex-direction: column;
  max-height: 25vh;
  overflow-y: auto;
  height: 9rem;
}

.ParameterWrapper {
  background: transparent;
  text-align: center;
  max-height: fit-content;
  padding: 0.25rem;
}

.ParameterWrapper:focus-visible {
  outline: transparent;
}

.ParameterEditor {
  display: flex;
  align-items: center;
}

.ParameterEditor > input,
.ParameterEditor > select {
  width: fit-content;
}

.FixedOptions {
  margin-left: 1rem;
  grid-area: options;
}

.AddParameterButton {
  font-size: 0.5rem;
  display: flex;
  justify-content: center;
  align-self: center;
  width: fit-content;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.AddParameterButton:hover {
  scale: 125%;
  transition: scale 0.2s;
  cursor: pointer;
}
