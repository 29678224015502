.RelationshipEditorWrapper {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.RelationshipEditorWrapper::-webkit-scrollbar {
  display: none;
}

.SmallFont {
  font-size: 0.875rem;
}

.ListWrapper {
  width: 100%;
  margin-right: 16px;
  font-size: 0.875rem;
  overflow-y: auto;
}

.ColumnWrapper {
  margin-left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px;
}

.ColumnWrapper:hover {
  cursor: pointer;
}

.SelectedColumn {
  border-radius: 5px;
}

.ButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Button {
  margin: 0 8px;
}

.ReorderButton:hover {
  cursor: grab;
}

.DetailsWrapper {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.KeyIcon {
  color: yellow;
}

.KeyValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px;
  border: 1px solid transparent;
}

.Key {
  margin-right: 8px;
  max-width: 150px;
}

.Value {
  margin-right: 8px;
  max-width: 150px;
  font-size: 13px;
}
