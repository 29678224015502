/* Nav */
.LogicBuilderNavWrapper {
  justify-content: space-between;
  height: 40px;
  width: 100%;
}

.LogicBuilderNav {
  flex-wrap: nowrap;
}

.LogicBuilderNav i {
  padding-right: 0 !important;
}

.HideTabName {
  display: none;
}

@media only screen and (min-width: 920px) {
  .HideTabName {
    display: inline;
  }
}

/* Wrapper */
.LogicBuilderWrapper {
  padding: 32px;
  position: absolute;
  top: 100px;
  left: 60px;
  width: calc(100% - 60px);
  height: calc(100% - 100px);
  overflow: hidden;
  background-color: #2b3035;
}

.LogicEditorWrapper {
  padding: 20px;
  height: 100%;
  width: 100%;
}

.LogicEditorListWrapper {
  height: auto;
}

/* Title */
.LogicBuilderHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 32px;
  font-weight: 500;
  padding: 8px 8px 25px 8px;
  margin-bottom: 10px;
  height: 25%;
  width: 100%;
}

/* Search bar + buttons */
.LogicBuilderControl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}

.LogicBuilderControlSearch {
  width: 100%;
  max-width: 400px;
  min-width: 100px;
  padding-right: 16px;
}

.LogicBuilderControlButtons {
  display: flex;
  justify-content: flex-end;
  padding: 4px 0px;
}

.ControlButton:not(:last-child) {
  margin-right: 16px;
}

.ControlButton {
  white-space: nowrap;
}

/* Tabs */
.TabsWrapper {
  height: 75%;
  width: 100%;
  overflow: hidden;
}
.Tabs {
  display: flex;
  align-items: center;
  margin: 8px;
}

/* Sidebars */
.sidebar_left {
  position: absolute;
  top: 0;
  left: 0;
  background: #27323a;
  width: 240px;
  height: 100vh;
  z-index: 3;
  border-right: 1px solid #27323a;
  overflow-y: auto;
}

.sidebar_right {
  position: absolute;
  top: 0;
  right: 0;
  background: #27323a;
  width: 240px;
  height: 100vh;
  z-index: 3;
  border-left: 1px solid #27323a;
  overflow-y: auto;
  color: #0d6efd;
}

.leftAside {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 4;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.rightAside {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 4;
  overflow: auto;
}

.rightToolbarContainer {
  position: absolute;
  top: 60px;
  right: 0;
  width: 240px;
  height: calc(100vh - 60px);
  z-index: 3;
}

.leftToolbarContainer {
  position: absolute;
  top: 60px;
  left: 64px;
  width: 240px;
  height: calc(100vh - 60px);
  z-index: 3;
}

.leftColapseArrow {
  position: absolute;
  z-index: 10;
  top: 40vh;
  right: -20px;
  background-color: #c4c4c4;
  width: 25px;
  text-align: center;
  border-radius: 50%;
  color: #333;
  opacity: 0.6;
}

.rightColapseArrow {
  position: absolute;
  z-index: 10;
  top: 40vh;
  left: -20px;
  background-color: #c4c4c4;
  width: 25px;
  text-align: center;
  border-radius: 50%;
  color: #333;
  opacity: 0.6;
}

.leftColapseArrow:hover,
.rightColapseArrow:hover {
  opacity: 1;
  transform: scale(1.2);
  cursor: pointer;
}

.tabList {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.moreIconRight {
  width: fit-content;
  position: absolute;
  right: 6px;
  top: 1px;
  cursor: pointer;
}

.moreIconLeft {
  width: fit-content;
  position: absolute;
  left: 6px;
  top: 1px;
  cursor: pointer;
}

.moreIconLeft:hover,
.moreIconRight:hover {
  transform: scale(1.1);
}

.liTabItem {
  margin-right: 0.3rem;
}

.tabListContainer {
  padding-top: '12px';
}

.toolbarContent {
  height: 70%;
  max-height: 80%;
  min-height: 8%;
  overflow: hidden;
}

.layersContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
}

.layersContent {
  height: 100%;
  overflow-y: auto;
  font-size: 1rem;
}

.tab-content {
  height: 90%;
}

.spaceBetween {
  justify-content: 'space-between';
}

.formStyle {
  display: flex;
  flex-direction: column;
}

.editButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.hoverButton{
  cursor: pointer;
  margin-left: 5px;
}

.hoverButton:hover{
  color: #fff !important;
}