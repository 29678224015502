.contentButtons {
  width: fit-content;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonContent {
  height: auto;
  background: transparent;
  border: none;
  padding: 0px 5px 0px 5px;
}
.buttonContent > i {
  font-size: 13px;
}

.buttonContent > span {
  font-size: 12px;
}