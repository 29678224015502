.FunctionSignatureWrapper {
    border-width: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.HeaderEditorWrapper {
    border-width: 2px;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
}

.hoverButton:hover{
    color: #fff !important;
}