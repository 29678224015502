.accordion-item {
  background-color: transparent !important;
  border: none;
}

.accordion-button {
  background-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: inherit;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}

.sectionTitle {
  font-size: 1rem;
}
