.Section {
  padding: 0;
  margin-bottom: 10px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.SectionTitleDiv {
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.SectionTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 38px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.Description {
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  display: flex;
  align-items: center;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.FormDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  flex: none;
  flex-grow: 0;
}

.FormTitle {
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin: 0;
  flex: none;
  flex-grow: 0;
}

.FormControl {
  flex-direction: row;
  align-items: center;
  gap: 16px;

  margin-bottom: 0;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.FormSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 3px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.FormContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 0 0;
  gap: 40px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.Form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

@media (max-width: 768px) {
  .FormControl {
    width: 15rem !important;
    text-align: center;
  }

  .Section {
    height: 45vh;
  }
}

.SubmitButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}

.PricingCardsWrapper {
  padding: 16px 0 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.SubscriptionPlanWrapper {
  display: flex;
  justify-content: center;
  padding: 16px 0 0;
  gap: 40px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
