.sidebar_left {
  position: absolute;
  top: 0;
  left: 0;
  background: #27323a;
  width: 240px;
  height: 100vh;
  z-index: 3;
  border-right: 1px solid #27323a;
  overflow-y: auto;
}

.sidebar_right {
  position: absolute;
  top: 0;
  right: 0;
  background: #27323a;
  width: 240px;
  height: 100vh;
  z-index: 3;
  border-left: 1px solid #27323a;
  overflow-y: auto;
  color: #0d6efd;
}

.button {
  cursor: pointer;
}

.buttonInactive:hover,
.buttonInactive:active,
.buttonInactive:focus {
  filter: brightness(0.9);
}

.leftAside {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 4;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.rightAside {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 4;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.rightToolbarContainer {
  position: absolute;
  top: 60px;
  right: 0;
  width: 240px;
  height: calc(100vh - 60px);
  z-index: 3;
}

.leftToolbarContainer {
  position: absolute;
  top: 60px;
  left: 64px;
  width: 240px;
  height: calc(100vh - 60px);
  z-index: 3;
}

.leftColapseArrow {
  position: absolute;
  z-index: 10;
  top: 40vh;
  right: -20px;
  background-color: #c4c4c4;
  width: 25px;
  text-align: center;
  border-radius: 50%;
  color: #333;
  opacity: 0.6;
}

.rightColapseArrow {
  position: absolute;
  z-index: 10;
  top: 40vh;
  left: -20px;
  background-color: #c4c4c4;
  width: 25px;
  text-align: center;
  border-radius: 50%;
  color: #333;
  opacity: 0.6;
}

.leftColapseArrow:hover,
.rightColapseArrow:hover {
  opacity: 1;
  transform: scale(1.2);
  cursor: pointer;
}

.tabList {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.moreIconRight {
  width: fit-content;
  position: absolute;
  right: 6px;
  top: 1px;
  cursor: pointer;
}

.moreIconLeft {
  width: fit-content;
  position: absolute;
  left: 6px;
  top: 1px;
  cursor: pointer;
}

.moreIconLeft:hover,
.moreIconRight:hover {
  transform: scale(1.1);
}

.liTabItem {
  margin-right: 0.3rem;
}

.tabListContainer {
  padding-top: 5px;
}

.layersContent {
  height: calc(100% - 40px);
  overflow-y: auto;
  padding: 0 0 4rem;
  margin-top: 1rem;
  font-size: 0.8rem;
  scroll-behavior: smooth;
  font-size: 0.8rem;
}

.toolbarContent {
  height: 45%;
  max-height: 80%;
  min-height: 8%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.rightToolbarContent {
  height: 100%;
  min-height: 8%;
  overflow: hidden;
  flex-direction: column;
  overflow: hidden;
}

.layersWrapper {
  height: 400px;
  padding: 16px 16px 8px 0px;
  height: 30%;
  max-height: 80%;
}

.layersContainer,
.componentsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.componentsContainer {
  overflow-y: hidden;
}

.layersContainer {
  overflow-y: auto;
}

.defaultInput {
  width: 50%;
}

.toolbarContent {
  position: relative;
}
