.ViewAndPropertyWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.ObjectEditorWrapper {
  width: 100%;
  height: 100%;
}

.PropertyEditorWrapper {
  width: 100%;
}

.ObjectViewWrapper {
  min-width: 40%;
  margin-right: 16px;
  font-size: 0.875rem;
  overflow-y: auto;
}

.ActionsToolbar {
  display: flex;
}

.SelectTable {
  height: 25px;
  min-width: 150px;
  font-size: 0.875rem;
  padding: 2px 36px 2px 12px;
}

.ObjectCompleteCheckbox {
  height: 25px;
  font-size: 0.875rem;
  padding: 2px 0px 2px 30px;
}

.ToolbarButtons {
  margin-right: 8px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px !important;
}

.KeyValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 4px;
  border: 1px solid transparent;
}

.KeyValue:hover {
  cursor: pointer;
}

.SelectedProperty {
  border-radius: 5px;
}

.LeftSideWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Key {
  margin-right: 8px;
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.Value {
  margin-right: 8px;
  max-width: 150px;
  font-size: 13px;
}

.SmallFont {
  font-size: 0.875rem;
}

.Checkbox {
  display: flex;
  align-items: center !important;
}

.KeyIcon {
  color: yellow;
}

.EntityIcon {
  margin-right: 4px;
}

.DragIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20px;
  cursor: move;
}

.errorMsg {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}
