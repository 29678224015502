.EditorPanelRoot {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 4fr;
  grid-template-areas: 'signature signature' 'parameters options';
}

.ReturnTypeAndName {
  display: flex;
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 65%;
  height: 13.5rem;
  padding-right: 1rem;
  grid-area: signature;
  border-right: var(--bs-border-width) solid var(--bs-border-color); */
}

.ReturnType {
  width: 40%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  display: flex;
  justify-content: space-between;
  margin-right: 0.5rem;
}

.ReturnTypeListCheck {
  padding: 0 0.75rem;
  position: relative;
  float: right;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: var(--bs-border-radius);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.Parameters {
  width: 100%;
  grid-area: parameters;
  display: flex;
  flex-direction: column;
  max-height: 25vh;
  overflow-y: auto;
}

.ParameterWrapper {
  background: transparent;
  text-align: center;
  max-height: fit-content;
  padding: 0.25rem;
}

.ParameterWrapper:focus-visible {
  outline: transparent;
}

.ParameterEditor {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ParameterEditor > input,
.ParameterEditor > select {
  width: fit-content;
}

.FixedOptions {
  margin-left: 1rem;
  grid-area: options;
}

.AddParameterButton {
  font-size: 0.5rem;
  display: flex;
  justify-content: center;
  align-self: center;
  width: fit-content;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.AddParameterButton:hover {
  scale: 125%;
  transition: scale 0.2s;
  cursor: pointer;
}

.FunctionSignatureWrapper {
  border-width: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.HeaderEditorWrapper {
  border-width: 2px;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  margin-top: -1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding-left: 4rem;
}


.optionsWrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}

.container {
  width: 44%;
  height: 11rem;
  margin-left: 2rem;
}

.checkOption {
  display: flex;
  align-items: center;
}

.containerRelatedEntityData {
  display: flex;
  margin-top: 0.5rem;
}

@media (max-width: 1470px) {
  .HeaderEditorWrapper {
    flex-direction: column;
    overflow-x: hidden;
  }

  .container {
    width: 100%;
    margin-left: 0;
    padding-top: 2.5rem;
  }
}
