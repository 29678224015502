.frameWrapperContainer {
  position: relative;
  width: calc(100vw - 545px);
  height: calc(100vh - 60px);
  background: #262626;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 240px;
  padding-top: 50px;
}


