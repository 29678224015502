.cardContainer {
  cursor: grab;
  background: rgb(230, 236, 239) none repeat scroll 0% 0%;
  border-color: currentcolor currentcolor currentcolor rgb(13, 110, 253);
  border-style: none none none solid;
  border-width: medium medium medium 4px;
  border-image: none 100% / 1 / 0 stretch;
  font-family: monospace;
}

.searchIcon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.paneToolbarContent {
  height: calc(100%);
  max-height: 100%;
  overflow-y: auto;
}
