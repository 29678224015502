.headerIcon {
  font-size: 1rem;
  margin: 0.5rem;
}

.headerContainer {
  font-size: 1rem;
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  cursor: pointer;
}

.bodyContainer {
  font-size: 1rem;
  border: 1px solid var(--bs-border-color);
  border-top: 0;
}

.contentContainer {
  font-size: 1rem;
  font-weight: 600;
  white-space: pre-line;

  height: 5rem;
  max-height: 5rem;
  overflow-y: scroll;

  padding: 0.1rem 0.5rem;

  background-color: inherit;
}

.contentDarkTheme {
  color: #d4d4d4;
  background-color: #1e1e1e;
}

.contentLightTheme {
  color: black;
  background-color: #fffffe;
}