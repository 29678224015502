.TagsWrapper {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  gap: 0.5em;
  font-size: 0.875rem;
  overflow: auto;
  height: 10rem;
}

.TagAddButton {
  font-size: 0.875rem;
  padding: 0 10px 0 10px;
  border-radius: 20px;
}
