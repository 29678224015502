.framename {
  position: absolute;
  top: -27px;
  right: 10px;
  color: #ececec;
  font-weight: bold;
}

.enumFrame {
  background-color: #fafafa;
  width: 200px;
  -webkit-box-shadow: 0px 7px 15px -2px rgba(111, 111, 111, 0.57);
  box-shadow: 0px 7px 15px -2px rgba(111, 111, 111, 0.57);
  animation: shake 0.2s;
  animation-iteration-count: initial;
}

@keyframes shake {
  0% {
    transform: rotate(0deg) translateX(3px);
    opacity: 0%;
  }

  25% {
    transform: rotate(-2deg) translateX(0px);
    opacity: 25%;
  }

  50% {
    transform: rotate(0deg) translateX(-3px);
    opacity: 50%;
  }

  75% {
    transform: rotate(2deg) translateX(0px);
    opacity: 75%;
  }

  100% {
    transform: rotate(0deg) translateX(0px);
    opacity: 100%;
  }
}

.tableTitle {
  border-bottom: 2px solid #5e5e5e;
}

.tableTitle th {
  padding: 5px;
  background-color: #b8f7e2;
}

.tableRow td {
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.tableRow td:last-child {
  padding-bottom: 0.5rem;
}

.tableFrame tr:nth-child(odd) td {
  background-color: white;
}

.tableFrame tr:nth-child(even) td {
  background-color: rgb(232, 232, 232);
}

.enumTitle th {
  padding: 0.7rem;
}

.selectedEnum {
  border: 5px solid #0d6efd;
  border-radius: 5px;
}

.selectedEnum .tableFrame {
  box-shadow: 0px 7px 15px 5px rgba(13, 110, 253, 0.57);
}

.newTableRow td {
  color: #8e8e8e;
  padding-left: 8px;
}

.tableFrameContainer {
  position: relative;
  z-index: 2;
}

.oneToManyPoint {
  right: -15px;
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 1;
  background-color: #0d6efd;
  bottom: 0px;
  border-radius: 0px 50px 50px 00px;
  z-index: 1;
  transition: cubic-bezier(1, 0, 0, 3.18) 0.1s;
}
