.ActionsToolbar {
  display: flex;
}

.ObjectCompleteCheckbox {
  height: 25px;
  font-size: 0.875rem;
  padding: 2px 0px 2px 30px;
}

.ToolbarButtons {
  margin-right: 8px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px !important;
}

.SmallFont {
  font-size: 0.875rem;
}

.SearchBox {
  height: 25px;
  min-width: 150px;
  font-size: 0.875rem;
  padding: 2px 36px 2px 12px;
}
