.SubmitButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}

.FormTitle {
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin: 0;
  flex: none;
  flex-grow: 0;
}

.FormSection {
  /* chekcboxItem */

  /* Auto layout */

  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.FormControl {
  /* chekcboxItem */

  /* Auto layout */

  flex-direction: row;
  align-items: center;
  gap: 16px;

  margin-bottom: 0px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.FormCheckInput {
  /* Checked */
  width: 13px;
  height: 13px;
  border-radius: 2px;
  margin-top: 0.35em;
}

.FormCheckLabel {
  /* Show structure grids */

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 21px;
  display: flex;
  align-items: center;

  height: 23px;

  padding-left: 6px;

  color: #333333;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.FormDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  flex: none;
  flex-grow: 0;
}

.Form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
