.dependencyItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  }

  .moduleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .moduleName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px; 
    max-width: calc(100% - 50px);
  }