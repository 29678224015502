.infoBox {
  position: absolute;
  z-index: 2;
  pointer-events: auto;
  cursor: default;
  top: 0px;
  left: -2px;
  padding: 0 8px;
  max-width: 225px;
  height: 30px;
  background: #0d6efd;
  color: #ececec;
  font-size: 1rem;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoBoxDashed {
  background: #0d6efd;
}

.selectionBox {
  border: 0.0625rem solid #0d6efd;
  position: absolute;
  z-index: 2;
  pointer-events: none;
}

.overlayBox {
  z-index: 2;
  position: absolute;
  border: 2px solid #7950f2;
  pointer-events: none;
}

.overlayStripes {
  background: repeating-linear-gradient(45deg, #ffe3e3, #ffe3e3 10px, #e03131 10px, #e03131 20px);
  opacity: 0.4;
}

.selectionBoxSolid {
  border: 0.12rem solid #6f42c1;
}

.gridBox {
  border: 0.1625rem dashed #9e9e9e;
}

.marginBox {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  background-color: transparent;
  border-style: solid !important;
  border-color: rgba(240, 90, 43, 0.3) !important;
}

.paddingBox {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  background-color: transparent;
  border-style: solid !important;
  border-color: rgba(104, 159, 56, 0.3) !important;
}

.resizeHandle {
  background: #0d6efd;
  border-radius: 10%;
  position: absolute;
  pointer-events: all !important;
  border: 0.0625rem solid #0d6efd;
}

.topResizeHandle,
.bottomResizeHandle {
  width: 100%;
  height: 0.25rem;
}

.leftResizeHandle,
.rightResizeHandle {
  width: 0.25rem;
  height: calc(100% + 4px);
}

.leftResizeHandle:hover,
.rightResizeHandle:hover {
  width: 0.4rem;
  background: #0d6efd;
}

.topResizeHandle:hover,
.bottomResizeHandle:hover {
  height: 0.4rem;
  background: #0d6efd;
}

.top {
  left: 0;
  top: -0.1rem;
  cursor: s-resize;
}

.left {
  top: -0.1rem;
  left: -0.1rem;
  cursor: w-resize;
}

.right {
  top: -0.1rem;
  right: -0.3rem;
  cursor: w-resize;
}

.bottom {
  left: 0;
  bottom: -0.2rem;
  cursor: s-resize;
}

.resizeTooltip {
  position: absolute;
  width: fit-content;
  font-size: 0.7rem;
  background: #333333;
  color: #ffffff;
  border-radius: 4px;
  padding: 2px 6px;
  opacity: 0.9;
}

.topResizeTooltip .resizeTooltip > p {
  margin: 0;
}

/* --------SelectionToolbar-------- */

.SelectionToolbarWrapper {
  position: absolute;
  z-index: 2;
  background: #0d6efd;
  color: #ececec;
  font-size: 1rem;
  padding: 0;
  overflow: hidden;
  pointer-events: auto !important;
  border-radius: 4px 4px 0 0;
  cursor: default;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: fit-content;
}

.SelectionToolbarWrapper div {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SelectionToolbarWrapper div:hover {
  background: #0c53be;
}

/* -------- ElementHover -------- */

.Hover {
  position: absolute;
  pointer-events: none;
  border: 0.0625rem solid #93beff;
  background: #93beff;
}

.HoverTop {
  width: 100%;
  height: 0.25rem;
  left: 0;
  top: -0.1rem;
}

.HoverBottom {
  width: 100%;
  height: 0.25rem;
  left: 0;
  bottom: -0.2rem;
}

.HoverLeft {
  height: calc(100% + 4px);
  width: 0.3rem;
  top: -0.1rem;
  left: -0.1rem;
}

.HoverRight {
  height: calc(100% + 4px);
  width: 0.3rem;
  top: -0.1rem;
  right: -0.3rem;
}

.HoverLabel {
  background: #93beff;
}
