.singleConnector {
  pointer-events: visibleStroke;
}

.singleConnector:hover .edgePath {
  stroke-width: 6px;
  cursor: pointer;
  stroke: #6ea8fe !important;
}

.edgePath:hover {
  stroke: #6ea8fe !important;
}

.edgePath {
  stroke-width: 4px;
  stroke: #8d8d8d;
  animation: dash 0.5s linear;
}

.selectedEdge {
  stroke-width: 4px;
  stroke: #0d6efd;
}

@keyframes dash {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 20%;
  }

  60% {
    opacity: 60%;
  }

  100% {
    opacity: 100%;
  }
}
