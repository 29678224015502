.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
}

.sidebar {
  width: max-content;
  padding: 10px;
  border-right: 1px solid #ddd;
  margin-top: 50px;
  background-color: #252525;
}

.breadCrumb {
  color: inherit;
  font-size: 12px;
}

.modalMain {
  width: 90vw !important;
  max-width: 90vw;
}

.folder {
  margin-bottom: 15px;
}

.folderName {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-weight: bold;
  font-size: small;
  text-overflow: ellipsis;
}

.files {
  margin-left: 10px;
  padding-left: 5px;
  font-size: small;
}

.file {
  cursor: pointer;
  margin-bottom: 5px;
}

.file:hover {
  text-decoration: underline;
}

.editorContent {
  flex: 1;
  padding: 10px;
  width: 500px;
}

.verticalLine {
  border-left: 2px solid #ccc;
  margin-left: 18px;
  height: 100%;
  margin-top: -10px;
}
