.adjacentDropzoneContainer {
  position: absolute;
  pointer-events: none;
  background-color: rgba(0, 0, 255, 0.3);
}

.input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: var(--placeholder-color);
}
.input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: var(--placeholder-color);
  opacity: 1;
}
.input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: var(--placeholder-color);
  opacity: 1;
}
.input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--placeholder-color);
}
.input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--placeholder-color);
}

.input::placeholder {
  /* Most modern browsers support this now. */
  color: var(--placeholder-color);
}

/* Automatic anchors component */
/* -------------------------------- */


.anchorPoint:hover{
  background-color: rgb(244, 150, 240);
}

.automaticAnchor{
  position: absolute;
  padding: 4px;
  pointer-events: none;
}

.anchorPoint{
  background-color: rgba(113, 43, 183, 0.2);
  width: 100%;
  height: 100%;
}

.selectedAnchorPoint{
  background-color: rgba(114, 4, 224, 0.3);
}