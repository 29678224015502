.ActionInput {
  text-align: center;
  color: rgba(13, 110, 253);
  font-size: 0.9rem;
  font-weight: 600;
  border: 0;
  margin-bottom: 0.2rem;
  background: rgb(var(--bs-primary-bg-rgb));
}

.ActionInputL:active,
.ActionInput:focus,
.ActionInput:focus-visible,
.ActionInput:hover {
  background: var(--bs-info-bg-subtle);
  outline: none;
}

.ActionInput::placeholder {
  color: rgba(13, 110, 253);
  font-weight: bold;
}

.ActionInputLabel {
  margin-bottom: 0px;
  text-align: center;
  font-size: 0.9rem;
}

.Options {
  position: absolute;
  z-index: 10;
  overflow: auto;
  max-height: 150px;
  width: fit-content;
  padding-left: 0;
}

.Option {
  position: relative;
  opacity: 1;
  background: var(--bs-info-bg-subtle);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
