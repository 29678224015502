.workboard {
  position: relative;
  width: calc(100vw - 66px);
  height: calc(100vh - 60px);
  background: #e5e5e5;
  overflow: hidden;
}

.framesContainer {
  transform-origin: 0 0;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.controlsContainer {
  position: absolute;
  bottom: 30px;
  z-index: 3;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  transition: ease-in-out 0.2s;
}

.controlsContainer > span {
  display: flex;
  align-content: center;
}

.controlsContainer span {
  color: #ecebeb;
}

.constrolsContainer span {
  display: flex;
  align-content: center;
}

.leftBottom {
  left: 220px;
}

.connectorContainer {
  z-index: 2;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  pointer-events: none;
}

.contextMenuItem {
  cursor: pointer;
}

.disabledContextMenuItem {
  pointer-events: all !important;
  cursor: default !important;
}

.controlButton:hover {
  background-color: #0d6efd !important;
  color: #f5f5f5;
}

.subMenu {
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 5;
  padding: 0;
  margin: 0;
}
