.cardWhite {
}

.cardWhite:hover {
  border-color: white;
}

.cardBlack {
}

.cardBlack:hover {
  border-color: black;
}

.toggleWrappper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin-left: 50px;
  flex-wrap: wrap;
}

.toggleItem{
  margin: 2px;
  margin-top: 5px;
}
