.title {
  font-weight: 370;
  letter-spacing: 0.457143px;
  font-size: 40px;
  overflow-wrap: break-word;
}

.active {
  font-weight: bolder;
}

.overFlowText {
  overflow: hidden;
  width: 60%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
