.EditorHeaderContainer {
  margin-top: 1rem;
}

.HeaderWrapper {
  display: flex;
  align-items: center;
  padding-top: 1rem;
}

.EndpointInstance {
  margin-bottom: 0 !important;
  width: 100% !important;
}

.EndpointInstanceWrapper {
  width: calc(100% - 80px) !important;
}

.ActionButtonWrapper {
  width: 80px !important;
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--bs-body-bg);
  border: 1px solid var(--bs-border-color);
  border-radius: 4px;
  padding: 0.5rem;
}

.InformationWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.LeftSide {
  width: 40%;
}

.RightSide {
  width: 55%;
}

.Wrapper {
  overflow: hidden !important;
  padding-right: 0 !important;
  max-height: 100% !important;
}

.container {
  overflow: auto;
  max-height: 500px;
  padding-inline: 20px;
}

.lineSeparator {
}
