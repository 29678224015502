.ErrorAlertWrapper {
  position: fixed;
  bottom: 5px;
  left: 40%;
  width: 300px;
  z-index: 999999;
  border: 0px;
}

.FadeOut {
  animation: fadeout 1.6s;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
