.ActionHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.5rem;
}

.ActionIcon {
    font-size: 1.4rem;
    padding-left: 0.5rem;
    padding-right: 1rem;
}

.ActionInputs {
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.ActionInlineInputsWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 0.5rem;
    padding: 0.5rem;
}

.ActionInputsWrapper {
    max-width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-grow: 1;
    gap: 0.5rem;
    padding: 0.5rem;
}

.ActionOptions {
    padding-left: 0.5rem;
    font-size: 0.7rem;
    display: flex;
    gap: 0.2rem;
}

.hoverButton:hover{
    color: #fff !important;
}