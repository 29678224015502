.LeftSidebarContainer {
  display: flex;
  width: 64px;
  height: calc(100vh - 60px);
  color: #ffffff;
  background-color: #343a40;
  flex-direction: column;
  justify-content: space-between;
}

.LeftSidebarList {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.LeftSidebarListItem {
  color: white;
  opacity: 0.8;
  width: 40px;
  font-size: 32px;
}

.LeftSidebarSpaceItems {
  margin-bottom: 12px;
}

.KanbanIcon {
  transform: rotateX(180deg);
}

.LeftSidebarListItem:hover {
  opacity: 0.6;
}

.LeftSidebarSelectedItem,
.LeftSidebarSelectedItem:hover {
  opacity: 1;
}

.styles.SidebarSeparator {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.svgIcon {
  fill: white;
}

.beforeSepStyle {
  stroke: var(--bs-emphasis-color);
}

.afterSepStyle {
  stroke: var(--bs-dark-border-subtle);
}

.sepWrapper {
  width: fit-content;
  height: 1px;
}

.feedbackTitle{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 10px;
}

.feedbackTitle > p {
  margin-top: 10px;
  font-size: 20px;
}

.close {
  background-color: transparent;
  border-color: transparent;
}