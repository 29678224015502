.MaxText {
  display: block;
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ActionInputLabel {
  margin-bottom: 0;
  text-align: start;
  font-size: 0.9rem;
}

.CustomCodeInputsWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
  height: auto;
  min-height: 150px;
}

.CustomCodeWrapper {
  box-shadow: 0 0 2px 2px #333;
  width: 680px;
  height: 150px;
}

.ImportsEditor {
  display: flex;
  flex-direction: column;
  padding-left: 3px;
  width: 40%;
  height: min-content;
}

@media screen AND (max-width: 1720px) {
  .CustomCodeWrapper {
    width: 580px;
  }
}

@media screen AND (max-width: 1620px) {
  .CustomCodeWrapper {
    width: 480px;
  }
}


@media screen AND (max-width: 1520px) {
  .ActionInputLabel {
    display: none;
  }

  .CustomCodeInputsWrapper {
    flex-direction: column;
    justify-content: start;
    flex: 1;
  }

  .CustomCodeWrapper {
    width: 800px;
  }

  .ImportsEditor {
    flex-direction: row;
    gap: 1rem;
    width: 100%;
  }
}

@media screen AND (max-width: 1400px) {
  .CustomCodeWrapper {
    width: 700px;
  }
}

@media screen AND (max-width: 1300px) {
  .CustomCodeWrapper {
    width: 600px;
  }
}

@media screen AND (max-width: 1200px) {
  .CustomCodeWrapper {
    width: 500px;
  }

  .ImportsEditor {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
}

@media screen AND (max-width: 1100px) {
  .CustomCodeWrapper {
    width: 400px;
  }
}
