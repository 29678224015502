.note {
  font-size: 0.75rem;
  opacity: 0.5;
}

.textStyle {
  font-size: 0.75rem;
}

.textStyle > p {
  font-weight: 200;
}

.textStyle > span {
  font-weight: 200;
}

.tooltip-inner {
  background-color: red !important;
}

.bs-popover-top > .popover-arrow::after {
  bottom: var(--bs-popover-border-width) !important;
  border-top-color: red !important;
}
