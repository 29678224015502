.Section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.SectionTitleDiv {
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.SectionTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 38px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.FormContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 0 0;
  gap: 40px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.Description {
  width: 854px;
  height: 46px;

  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  display: flex;
  align-items: center;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.labelStyle{
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 23px;
}