.ViewAndPropertyWrapper {
  display: flex;
  flex-direction: row;
  height: 90%;
}

.ColumnEditorWrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
