.rowRelation {
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.rowRelation:hover {
  background-color: #e9ecef;
}

.ViewAndPropertyWrapper {
  display: flex;
  flex-direction: row;
  height: 90%;
}

.RelationshipEditorWrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
