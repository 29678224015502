.containerModal {
  background-color: transparent;
  display: hidden;
}

/* removing the line of modal */
.contentClassName {
  /* border: 0px; */
  border: 0px;
}

.appSettingsDialog {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.dialogSaveButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.editModalButton {
  cursor: pointer;
}

.clickedAndChoosenAssets {
  background-color: gray;
  border: none;
}

.containerButtonsModules {
  display: flex;
  align-items: center;
}

.containerButtonsModules > * {
  margin-right: 1rem;
}

.containerButtonS3Form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.containerButtonS3Form > :first-child {
  margin-right: 1rem;
}

.editModalButton {
  cursor: pointer;
}

.clickedAndChoosenAssets {
  background-color: gray;
  border: none;
}

.containerButtonsModules {
  display: flex;
  align-items: center;
}

.containerButtonsModules > * {
  margin-right: 1rem;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
}
