.SubmitButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}

.FormTitle {
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin: 0;

  flex: none;
  flex-grow: 0;
}



.FormSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 3px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.FormControl {
  flex: none;
  align-self: stretch;
  flex-grow: 0;

  box-shadow: 0 0 0 0 transparent !important;
}

.FormDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  flex: none;
  flex-grow: 0;
}

.Form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.checkbox {
  font-size: 0.875em;
  margin-top: 0;
  color: #333333;
}

.PasswordInputDetails {
  display: flex;
  justify-content: space-between;
  padding-right: 0;
  padding-left: 0;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .FormControl {
    width: 15rem !important;
  }

  .Form {
    width: 15rem !important;
  }
}
/* 
@media (max-width: 768px){
    .FormControl {
        width: 24rem !important;
    }

    .Form {
        width: 24rem !important;
    }
} */

input[readonly]:focus, 
textarea[readonly]:focus {
  box-shadow: none;
  outline: none;
}