.searchIcon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.viewHeader {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.viewHeaderCollapse {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: default;
}

.viewHeaderName {
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  padding-left: 8px;
}

.viewHeaderNewView {
  width: 24px;
  height: 24px;
  /* border-radius: 4px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.cssClassInstance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--bs-border-color);
  padding: 8px;
}

.cssClassInstanceActions {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.cssClassInstanceActions div {
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cssClassInstanceSelector {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.paneToolbarContent {
  height: calc(100% - 168px);
  max-height: 100%;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
