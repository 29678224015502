.containerButtons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.buttons {
  width: 150px;
  border: none !important;
}
