.themeContainer {
  height: calc(100% - 91px);
}

.themeHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.previewWrapper {
  background-color: rgb(147, 147, 147);
  border: 1px solid #333;
  border-radius: 5px;
  padding: 1rem;
}

.label {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputs {
  margin: 0.2rem 0;
  width: 100%;
  overflow: hidden;
}

.inputBox {
  height: 1rem;
  background-color: aliceblue;
  margin: 0.2rem 0.5rem;
  width: 100%;
}

.buttons {
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding: 0.5rem 1.2rem 0;
}

.previewButton {
  width: 2rem;
  height: 1rem;
  background-color: blue;
}

.selectedTheme {
  /* background-color: rgb(190, 190, 190); */
}

.themeCard:hover {
  /* background-color: rgb(226, 225, 225); */
  cursor: pointer;
}

.themeHeader > label {
  font-weight: 600;
}

.palette {
  display: flex;
}

.color {
  display: flex;
  width: 30px;
  height: 10vh;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 0.68rem;
  cursor: pointer;
}

.color > span {
  transform: rotate(-90deg);
  transition: ease-in-out 0.1s;
  opacity: 50%;
}

.color:hover {
  width: 70px;
}

.color:hover > span {
  transform: rotate(0deg);
}

.selected {
  background-color: rgb(145, 145, 145);
}

.btnPrimary {
  background-color: blue;
}

.btnSecondary {
  background-color: #333;
}

.actionButton {
  display: flex;
}

.actionButton:hover {
  transform: scale(1.1);
}

.colorProperty {
  padding: 0.5rem;
  border: 1px solid #dadada;
}

.colorProperty > input {
  border: 0;
  text-align: center;
  color: #a7a7a7;
  font-family: 'roboto';
  font-size: 0.9rem;
  font-weight: 600;
  width: 100%;
}

.colorKey {
  font-size: 1rem;
  font-family: 'roboto';
  color: #333;
}

.themeHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.showCopiedIcon {
  opacity: 100%;
}

.hiddenIcon {
  opacity: 0%;
  transition: ease-in-out 0.1;
}

.paneToolbarContent {
  height: calc(100% - 66px);
  max-height: 100%;
  overflow-y: auto;
}
