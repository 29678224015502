.itemContainer {
  width: 56px;
  height: 64px;
  position: relative;
  display: flex;
  padding-right: 0;
  cursor: pointer;
}

.sampleContent1 {
  position: relative;
  top: 10%;
  left: 2%;
  width: 16px;
  height: 80%;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.subSampleContent1 {
  position: relative;
  width: 100%;
  right: -112%;
  top: -24%;
  height: 48%;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.subSubSampleContent1 {
  position: relative;
  width: 100%;
  right: -112%;
  height: 33%;
  top: -56%;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.expandIcon {
  position: relative;
  top: 0;
  font-size: 10px;
  right: 10%;
}

.itemContainer:hover .sampleContent1 {
  animation-name: sideMenuAnimation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes sideMenuAnimation {
  0% {
    width: 0px;
  }
  10% {
  }
  30% {
    width: 16px;
  }
  75% {
    width: 16px;
  }
  100% {
    width: 0px;
  }
}

.collapseSampleContent1 {
  position: relative;
  top: 10%;
  left: 2%;
  width: 24px;
  height: 24%;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
  overflow: hidden;
}

.subCollapseSampleContent1 {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
  align-items: center;
}

.subSubCollapseSampleContent1 {
  position: relative;
  bottom: -4px;
  width: 80%;
  height: 40px;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
  margin: auto;
}
.expandIconCollapse {
  position: relative;
  top: 0;
  font-size: 10px;
  right: 10%;
}

.itemContainer:hover .collapseSampleContent1 {
  animation-name: collapseSampleContent1;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes collapseSampleContent1 {
  0% {
    height: 18%;
  }
  20% {
    height: 90%;
  }
  80% {
    height: 90%;
  }
  100% {
    height: 18%;
  }
}

.itemContainer:hover .expandIconCollapse {
  animation-name: rotateArrow;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes rotateArrow {
  0% {
    transform: rotate(0);
  }
  20% {
    transform: rotate(90deg);
  }
  80% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0);
  }
}

.itemContainer:hover .exampleBar {
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.expandIconCollapse .itemContainer:hover {
  color: var(--bs-tertiary-color) !important;
}

.itemWrapper:hover {
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.itemWrapper {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rightItemWrapper {
  transform: scaleX(-1);
}
