.LoginSignInText {
  text-align: center;
  font-weight: 600;
  font-size: 1.75rem;
  padding-bottom: 24px;
}

@media (max-width: 480px) {
  .LoginSignInText {
    font-size: 1.5rem;
  }
}

.placeholder::placeholder {
  color: #5e6063;
}

.LoginExternalLink {
  color: #007bff;
  font-size: 0.875em;
}

.LoginExternalLinkCenter {
  text-align: center;
}
