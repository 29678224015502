.EditorPanelRoot {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: 'signature signature signature signature signature' 'parameters parameters parameters parameters options';
}

.ReturnTypeAndName {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 75%;
  padding-right: 1rem;
  grid-area: signature;
}

.ReturnType {
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  display: flex;
  justify-content: space-between;
}

.ReturnTypeListCheck {
  padding: 0 0.75rem;
  position: relative;
  float: right;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: var(--bs-border-radius);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.Parameters {
  width: 100%;
  grid-area: parameters;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  overflow-y: visible;
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  padding-bottom: 0.5rem;
}

.ParametersWrapper {
  display: flex;
  flex-direction: column;
}

.ParameterWrapper {
  background: transparent;
  text-align: center;
  max-height: fit-content;
  padding: 0.25rem;
}

.ParameterWrapper:focus-visible {
  outline: transparent;
}

.ParameterEditor {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ParameterEditor > input,
.ParameterEditor > select {
  width: fit-content;
}

.FixedOptions {
  margin-left: 1rem;
  grid-area: options;
}

.check {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.AddParameterButton {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  text-align: center;
  align-self: center;
  width: fit-content;
  padding-top: 0.7rem;
  padding-bottom: 0.8rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  border-radius: 8px;
  background-color: #3b71ca;
  color: white;
}

.AddParameterButton:hover {
  scale: 125%;
  transition: scale 0.2s;
  cursor: pointer;
}

@media screen and (max-width: 2000px) {
  .EditorPanelRoot {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: 'signature signature signature signature signature' 'parameters parameters parameters parameters options';
  }
  .ReturnTypeAndName {
    width: 75%;
  }
}

@media screen and (max-width: 1400px) {
  .EditorPanelRoot {
    grid-template-columns: 1fr;
    grid-template-areas: 'signature' 'parameters' 'options';
  }
  .ReturnTypeAndName {
    width: 100%;
  }
  .Parameters {
    border-right: none;
    grid-template-columns: 1fr;
  }

  .ParameterWrapper {
    padding-left: 0;
  }

  .FixedOptions {
    margin-left: 0;
    display: flex;
    column-gap: 0.75rem;
  }

  .check > input {
    margin-left: 1rem !important;
  }
}

@media screen and (max-width: 1300px) {
  .EditorPanelRoot {
    grid-template-columns: 1fr;
    grid-template-areas: 'signature' 'parameters' 'options';
  }
  .ReturnTypeAndName {
    width: 100%;
  }
  .Parameters {
    border-right: none;
  }
  .FixedOptions {
    margin-left: 0;
    display: flex;
    column-gap: 0.75rem;
  }
}
