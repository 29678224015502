.searchIcon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.viewHeader {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.viewHeaderCollapse {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: default;
}

.viewHeaderName {
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  padding-left: 8px;
}

.viewHeaderNewView {
  width: 24px;
  height: 24px;
  /* border-radius: 4px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.blocksContainer {
  height: calc(100% - 160px);
  overflow-y: auto;
}
