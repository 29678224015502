.editing,
.highlighting {
  /* Both elements need the same text and space styling so they are directly on top of each other */
  margin: 10px;
  padding: 10px;
  border: 0;
  width: 100%;
  height: 150px;

  font-family: SourceCodePro;
  font-size: 1em;
  text-align: left;
}

.editing,
.highlighting,
.highlighting * {
  /* Also add text styles to highlighing tokens */
  font-size: 0.8rem;
  font-family: SourceCodePro;
  line-height: 16pt;
  tab-size: 2;
}

.editing,
.highlighting {
  position: absolute;
  top: 0;
  left: 0;
}

/* Move the textarea in front of the result */

.editing {
  z-index: 1;
}
.highlighting {
  z-index: 0;
}

/* Make textarea almost completely transparent */

.editing {
  color: transparent;
  background: transparent;
  caret-color: white;
}

/* Can be scrolled */
.editing {
  overflow: auto;
  white-space: nowrap; /* Allows textarea to scroll horizontally */
}

.editing {
  resize: none;
}

.highlighting[class*='language-'],
.editing[class*='language-'] {
  margin: 0;
  padding: 10px;
}

.highlighting,
.editing {
  margin: 0;
}
