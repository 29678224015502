.mainContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.labelContainer {
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
}

.inputsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;
  background-color: rgba(241, 245, 249, 0.9);
  border-radius: 3px;
  padding: 3px;
}

.containerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 25px;
  color: black;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px;
}

.containerBtn:hover {
  background-color: #ffffff;
  cursor: pointer;
}

.containerBtnClicked {
  background-color: #ffffff;
  cursor: pointer;
}

.inputs {
  display: flex;
  align-items: center;
  gap: 10px;
}

.inputNumber {
  width: 60px;
  height: 30px;
  text-align: center;
  border-radius: 4px;
}

.inputColor {
  width: 20px;
  height: 20px;
  text-align: center;
  border-width: 0px;
}

.resetButton {
  border: 0;
  background-color: transparent;
}
