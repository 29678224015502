.legendWrapper {
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
}

ul.separator {
  margin: 0;
  overflow: auto;
  max-height: 100%;
}

ul.separator > li {
  padding: 0.5em 0;
  border-bottom: 1px solid #ccc;
}

ul.separator > li:last-child {
  border-bottom: 0;
}

ul.separator > li {
  padding: 1em 0;
}

.avatarBackground {
  width: 32px;
  height: 30px;
  border-radius: 50px;
  overflow: hidden;
}

.teamsAvatar {
  width: 32px;
  height: 30px;
  overflow: hidden;
}

.teamsSetting {
  background-color: #0275d8;
  font-size: 13px;
  font-weight: bold;
  width: 100%;
  height: inherit;
  border-radius: 3px;
  padding: 4px;
  color: white;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 24px 0;
}

.title {
  font-size: larger;
  font-weight: 600;
}

.message {
  font-size: larger;
  padding: 16px 0;
  color: #6e6e6e;
}
