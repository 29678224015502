.relationshipPropsContainer {
  padding: 1rem;
}

.relationshipNameInput {
  font-size: 1rem;
  padding: 0 0 0 0.5rem;
  width: 100%;
}

.relationshipContainer {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0.2rem;
}
