.componentHeader {
}

.componentIcon {
  padding: 0.5rem;
  font-size: 2rem;
  margin-right: 0.5rem;
}

.componentType {
  font-size: 1.1rem;
  font-weight: bold;
}
