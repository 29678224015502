.appSettingsDialog {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.dialogSaveButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.clickedAndChoosenAssets {
  background-color: gray;
  border: none;
}
.containerButtonS3Form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.containerButtonS3Form > :first-child {
  margin-right: 1rem;
}
.dangerZoneContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
}

.dangerZoneLabels {
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 0.5rem 0;
}

.dangerZoneTitle {
  font-weight: 500;
  padding-top: 0.5rem;
}

.dangerZoneDescription {
  font-size: 0.8rem;
}


.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
}
