.bgWizard {
  background-image: url('http://localhost:3000/assets/wizard-background.svg');
  background-repeat: no-repeat;
  background-position: right 60px;
  background-size: 350px;
}

.wizardContainer {
  /* makes the wizard full-screen */
  min-height: calc(100vh - 60px);
  /* wizard navigation offset */
  padding-bottom: 110px !important;
}
