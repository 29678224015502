.colorContainer {
  display: flex;
  margin-bottom: 1rem;
}

.colorDiv {
  border-radius: 50px;
  width: 40px;
  height: 40px;
}

.themeHeader {
  display: flex;
  justify-content: space-between;
}

.radioToolbar input[type='radio'] {
  display: none;
}

.themeLabel {
  position: relative;
}

.radioToolbar label {
  border-radius: 10px;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 100%;
}

.radioToolbar input[type='radio']:checked + label {
  background-color: #0d6efd42;
}

.checked {
  color: white;
  background-color: rgb(25, 135, 84);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  cursor: default !important;
  opacity: 0.4;
}

.commingSoon {}

/* !important -> override bootstrap */
@media (max-width: 480px) {
    .commingSoon {
        left: 19px !important;
    }
}