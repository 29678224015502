/*------------------Containers------------------*/
.Row {
  --bs-gutter-x: 0rem;
}

.container {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  height: calc(100vh - 60px);
  overflow-x: hidden;
}

.RightContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;

  position: relative;
  padding: 32px 64px;
}

.LeftContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

/*------------------Title------------------*/

.TitleDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px 22px 16px;
  gap: 10px;

  height: 84px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.TitleText {
  margin: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

/*------------------Search------------------*/
.searchInputDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;

  height: 70px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

/*------------------Tabs------------------*/
.tabsDiv {
  display: flex;

  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  padding-left: 24px;
  gap: 8px;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.tab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  height: 41px;
  border-radius: 4px 4px 0px 0px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

.navLink {
  display: block;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 16px;
  gap: 10px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.tabText {
  margin-left: 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 32px;

  flex: none;
  order: 1;
  flex-grow: 1;
}

.tabLine {
  height: 1px;
  margin-right: 57px;

  border: 1px solid #e9ecef;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.temp {
  padding: 8px;
}

.arrow:after {
  content: '';
  float: right;

  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-right: 16px solid #e30f0f;
}

@media (max-width: 480px) {
  .LeftContainer {
    max-height: 23rem;
    margin-bottom: 3rem;
    overflow-y: scroll;
  }

  .container {
    height: auto;
  }
}
