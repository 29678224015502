.ExocodeNavbar {
  height: 60px;
  padding-top: 0;
  padding-bottom: 0;
}

.ExocodeNavbarBrand Image {
  display: inline-block;
  text-align: center;
}

.ExocodeNavbarProfileImage:hover {
  opacity: 0.7;
}

.ExocodeNavbarContainer {
  max-width: 100vw;
  display: flex;
  align-items: center;
}

.LoginNavbarContainer {
  max-width: 100vw;
  display: flex;
  align-items: center;
  background-color: #343a40;
}

.DropdownToggle {
  background-color: transparent !important;
  border: none;
  padding: 0;
}

.DropdownToggle::after {
  display: none !important;
}

.imageLogoContainer {
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.LoginExternalLink {
  color: #33a9b8;
}

.Button {
  color: white;
  background-color: #33a9b8;
  border-color: transparent;
}

.rightContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
}

.questionButton {
  background-color: #f8f9fa;
  border-color: transparent;
  position: relative;
  margin-right: 15px;
  border-radius: 50%;
  color: #343a40;
  width: 20px;
  height: 20px;
  top: 5px;
  font-size: small;
  opacity: 0.8;
}

.questionButton:hover {
  opacity: 0.6;
}

.feedbackButton {
  background-color: transparent;
  border-color: transparent;
  opacity: 0.8;
  position: relative;
  margin-right: 15px;
}

.feedbackButton:hover {
  background-color: transparent;
  border-color: transparent;
  opacity: 0.6;
}

.alertIcon {
  position: absolute;
  top: -10px;
  right: -5px;
  background-color: #e5b010;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 7px;
  font-weight: bold;  
  width: 16px;
  opacity: 0.9;
}
