.SmallFont {
  font-size: 0.875rem;
  margin-bottom: 0;
  cursor: drag !important;
}

.ClickableText:hover {
  cursor: pointer;
}

.ColumnButtonWrapper {
  display: flex;
  align-items: center;
}

.ColumnReorderButton:hover {
  cursor: grab;
}

.KeyIcon {
  color: yellow;
}

.ReturnTypeCombo {
  padding: 0 !important;
  color: var(--bs-body-color);
  border-radius: var(--bs-border-radius);
  border: var(--bs-border-width) solid var(--bs-border-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: var(--bs-body-bg);
  position: relative;
  flex-grow: 1;
  max-width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: text;
}

.ReturnTypeComboInput {
  color: var(--bs-body-color);
  text-align: center;
  border: none;
  background: transparent;
  overflow: hidden;
  width: 100%;
}

.ReturnTypeComboInput:focus-visible,
.ReturnTypeComboInput:focus {
  outline: none;
}

.ReturnTypeComboOptions {
  position: fixed;
  background-color: var(--bs-body-bg);
  z-index: 10;
  width: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 150px;
  padding-left: 0;
  cursor: default;
  margin-top: 10px;
}

.ReturnTypeComboOption {
  font-weight: normal;
  display: block;
  min-height: 1.2em;
  padding: 0 2px 1px;
  white-space: nowrap;
}
