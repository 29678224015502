.appSettingsDialog {
    margin-left: 1rem;
    margin-right: 1rem;
}

.dialogSaveButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
