.FindAccountInText {
  text-align: center;
  font-weight: 600;
  font-size: 1.75rem;
  padding-bottom: 24px;
}

.LabelInfo {
  padding-bottom: 1rem;
}

.ButtonContainer {
  width: 100%;
  margin-bottom: 24px;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

.Buttons {
  margin-left: 1rem;
}

.ButtonSpinner {
  margin-right: 10px;
}

.DisabledButtonSpinner {
  display: none;
}

@media (max-width: 480px) {
  .ButtonContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .Buttons {
    margin-bottom: 1rem;
    margin-left: 0px;
    width: 100%;
  }
}
