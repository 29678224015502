.StudioNavbar {
  height: 60px;
  color: #ffffff;
  background-color: #343a40;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 12px;
  display: flex;
}

.StudioNavbarBreadcrumbs ol {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.StudioNavbarBreadcrumbs a {
  color: white;
}

.StudioNavbarSelect {
  color: white;
  background: #343a40;
  border: #343a40;
  outline: none;
}

/* fix navbar width */
.StudioNavbarContainer {
  max-width: 50vw;
  padding-left: 0;
  margin-left: 12px;
  flex: 0.9;
}

.StudioNavbarContainer > a {
  margin-right: 12px;
}

.BreadcrumbDivider {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 8px;
  padding-right: 8px;
  color: gray;
}

.BreadcrumbItem {
  cursor: pointer;
}

.ExocodeNavbarProfileImage {
  color: #f8f9fa;
}

.ExocodeNavbarProfileImage:hover {
  opacity: 0.7;
  color: #f8f9fa;
}

.DropdownToggle {
  background-color: transparent !important;
  border: none;
  padding: 0;
}

.DropdownToggle::after {
  display: none !important;
}

.hidingFirst {
  display: flex;
}

.hidingSecond {
  display: flex;
}

.hidingThird {
}

.lastOne {
}

.buttonAutomation {
  margin-right: 1rem;
}

.buttonDownload {
}

.buttonDownload:hover {
  opacity: 0.5;
}

.containerBadge {
  width: min-content;
}

.qtdUpdateDownload {
  font-size: 12px;
  position: absolute;
  color: white;
  text-align: center;
  top: 5px;
  background-color: #dc3545;
  border-radius: 999px;
  width: 16px;
  padding-right: 1px;
  margin-left: 15px;
  opacity: 0.9;
}

.icon {
  font-size: 1.4rem;
  margin-right: 0.4rem;
  cursor: pointer;
}

.icon:hover {
  opacity: 0.7;
}

.containerElipsis {
}

.containerShowedElipsis {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 20px;
  top: 40px;
  padding: 20px;
  z-index: 9999;
  background-color: var(--bs-body-bg);
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 10px;
}

.feedbackButton {
  background-color: transparent;
  border-color: transparent;
  position: relative;
  margin-right: 15px;
  color: #f8f9fa;
}

.questionButton {
  background-color: #f8f9fa;
  border-color: transparent;
  position: relative;
  margin-right: 15px;
  border-radius: 99;
  color: transparent;
}

.feedbackButton:hover {
  background-color: transparent;
  border-color: transparent;
  opacity: 0.7;
  color: #f8f9fa;
}

.alertIcon {
  position: absolute;
  top: -10px;
  right: -5px;
  background-color: #e5b010;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 7px;
  font-weight: bold;
  width: 16px;
  opacity: 0.9;
}

.links:-webkit-any-link {
  text-decoration: none;
}

.userDropdownContainer {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.hidingfourth {
  display: flex;
}

.divider {
  color: #808080;
  padding: 0 0.5rem;
}

.viewTypeIcon {
  font-size: 14px;
  margin-right: 4px;
}

@media only screen and (max-width: 820px) {
  .hidingfourth {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  /* .StudioNavbarContainer {
    width: 600px;
    max-width: fit-content;
  } */

  .buttonAutomation {
    display: none;
  }

  .lastOne {
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    max-width: 60px;
  }
}

@media only screen and (max-width: 700px) {
  .hidingThird {
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    max-width: 80px;
  }
  .hidingSecond {
    display: none;
  }
}

@media only screen and (max-width: 520px) {
  .hidingSecond {
    display: none;
  }
  .hidingThird {
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    max-width: 80px;
  }

  .hidingfourth {
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    max-width: 80px;
  }
}

@media only screen and (max-width: 400px) {
  .hidingSecond {
    display: none;
  }

  .userDropdownContainer {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 464px) {
  .hidingSecond {
    display: none;
  }
}

@media only screen and (max-width: 379px) {
  .hidingThird {
    display: none;
  }
}

@media only screen and (max-width: 330px) {
  .hidingThird {
    display: none;
  }
}

@media only screen and (max-width: 330px) {
  .hidingThird {
    display: none;
  }
}

@media only screen and (max-width: 225px) {
  .lastOne {
    display: none;
  }
}
