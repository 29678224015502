.SubmitButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}

.FormTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 23px;
  display: flex;
  align-items: center;
  margin: 0;

  flex: none;
  flex-grow: 0;
}

.FormSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 3px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.FormControlConfirm {
  height: 38px;

  flex: none;
  align-self: stretch;
  flex-grow: 0;

  border-right: transparent;
}

.FormControlConfirmInvalid {
  height: 38px;
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e') !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  box-shadow: 0 0 0 0 transparent !important;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.FormDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  flex: none;
  flex-grow: 0;
}

.Form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}


.checkbox:checked {
  color: #333333;
}

.checkboxLabel {
  color: #333333;
  margin: 0 0 0 8px;
}

.MainInputGroup {
  flex-wrap: inherit;
}

.FormControlNewPassword {
  height: 38px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;

  border-right: transparent;
}

@media (max-width: 768px) {
  .FormControl {
    width: 10rem !important;
  }

  .Form {
    width: 15rem !important;
  }

  .FormControlConfirm {
    width: 8rem !important;
  }

  .FormControlConfirmInvalid {
    width: 8rem !important;
  }

  .FormControlNewPassword {
    width: 12rem !important;
  }
}
/* 
@media (max-width: 768px){
    .FormControl {
        width: 21rem !important;
    }

    .Form {
        width: 24rem !important;
    }

    .FormControlConfirm {
        width: 21rem !important;
    }

    .FormControlConfirmInvalid{
        width: 21rem !important;
    }

    .FormControlNewPassword{
        width: 21rem !important;
    }
} */
