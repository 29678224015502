.selectedColumn b {
  color: #0d6efd;
}

.indexColumnItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
}

.indexColumnItem p {
  padding: 0;
  margin: 0;
}

.indexColumnItem > b,
.indexColumnItem > p {
  margin-right: 0.5rem;
}

.indexDescription {
  width: 100%;
  text-align: left;
}

.indexDescription h5 {
  margin: 0;
}

.indexDescription p {
  margin: 0;
  color: #0d6efd;
}

.bodyContainer {
  padding: 0.5rem 0;
}

.bodyContainer > ul {
  padding: 0;
  margin: 0;
}

.bodyContainer > li {
  padding: 0;
  margin: 0;
}

.columnListItem:nth-child(odd) > div {
  background-color: rgb(255, 255, 255);
}

.columnListItem:nth-child(even) > div {
  background-color: rgb(232, 232, 232);
}

.columnListItem {
  border-bottom: 1px solid #dedede;
}

.columnListItem:last-child {
  border: 0;
}

.columnTypeSelect {
  width: min-content;
}

.saveIndex {
  margin: 1rem 0 0 0;
}

.indexSelectType {
  width: fit-content;
  margin-top: 4px;
}
