.language {
  height: 19px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
}

.select {
  /* Input State/Default */

  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 43%;
}

.bottomContent {
  display: flex;
  justify-content: space-between;
}

.btnAddTranslation {
  height: max-content;
  text-overflow: ellipsis;
}

.translationKey, .translationValue {
  display: block;
  max-width: 155px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (min-width: 768px) {
  .translationKey, .translationValue {
    max-width: 215px;
  }
}

@media screen and (min-width: 992px) {
  .translationKey, .translationValue {
    max-width: 295px;
  }
}

@media screen and (min-width: 1200px) {
  .translationKey, .translationValue {
    max-width: 355px;
  }
}

@media screen and (min-width: 1400px) {
  .translationKey, .translationValue {
    max-width: 415px;
  }
}
