.connectorSvg {
  overflow: visible;
  pointer-events: none;

  height: 100%;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
}

.svgGroup {
  pointer-events: none;
}
