.positionContainer {
  width: 100px;
  height: fit-content;
  cursor: pointer;
  margin: 0;
  transition: ease-in-out 0.2s;
}

.positionIcon {
  font-size: 12px;
  transition: all ease-in-out 0.1s;
}

.positionIcon:hover {
  color: #5797f8;
  transform: scale(1.2);
}

.selected {
  color: #0d6efd !important;
}

.selectedBorder {
  border-color: #0d6efd !important;
}

.positionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 'fit-content';
}

.alignContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.orientationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.orientationButton {
  padding: 0.05rem 0.35rem;
  height: fit-content;
  border-radius: 5px;
  border: 1px solid transparent;
  transform: rotate(90deg);
}

.orientationButton:hover {
  cursor: pointer;
}


.justifyContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.justifyBetweenWrapper {
  height: fit-content;
  padding: 0.35rem 0.35rem;
  display: flex;
  justify-content: center;
  width: 6rem;
  cursor: pointer;
  font-size: 0.75rem;
}

.justifyBetweenContainer {
  display: flex;
  height: fit-content;
}

.justifyBetweenIcon {
  width: fit-content;
  padding-left: 0.3rem;
}

.justifyBetweenIcon:last-child {
  padding-right: 0.3rem;
}

.justifyBetweenContainer:hover {
  background-color: #0d6dfd27;
}

.justifyBetweenContainer:hover > .justifyBetweenIcon {
  padding-left: 0.55rem;
}

.justifyEvenlyWrapper {
  height: fit-content;
  padding: 0.35rem 0.35rem;
  display: flex;
  justify-content: center;
  width: 6rem;
  cursor: pointer;
  font-size: 0.75rem;
}

.justifyEvenlyContainer {
  display: flex;
  height: fit-content;
}

.justifyEvenlyIcon {
  width: fit-content;
  padding-left: 0.9rem;
}

.justifyEvenlyIcon:last-child {
  padding-right: 0rem;
}

.justifyEvenlyIcon:first-child {
  padding-left: 0rem;
}

.justifyEvenlyContainer:hover {
  background-color: #0d6dfd27;
}

.justifyEvenlyContainer:hover > .justifyEvenlyIcon {
  padding-left: 1.1rem;
}

.justifyEvenlyContainer:hover > .justifyEvenlyIcon:first-child {
  padding-left: 0rem;
}

.revertPositionContainer {
  transform: rotate(-90deg) scaleX(-1);
}

.labelName {
  padding:0.5rem 0rem 0.5rem 0rem;
}

.icon-rotated {
  transform: rotate(90deg);
}