.content {
  width: 200px;
  border-top: 1px solid #495057;
  border-bottom: 1px solid #495057;
  margin-bottom: 1rem;
}

.label{
  margin: 1rem 0 0.9rem 0;
}

.spacingContainer {
  border-top: 1px solid #495057;
  width: 200px;
  height: 160px;
}

.marginContainer {
  width: 100%;
  height: 100%;
  background-color: rgb(226, 173, 157);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px dashed gray;
}

.borderContainer {
  width: 71%;
  height: 70%;
  background-color: rgb(208, 122, 196);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid gray;
}

.paddingContainer {
  width: 67%;
  height: 55%;
  background-color: rgb(156, 200, 118);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px dashed gray;
}

.elementContainer {
  width: 28%;
  height: 28%;
  background-color: rgb(112, 112, 112);
}

.labelMargin {
  color:#212529;
  font-size: 10px;
}

.divLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.spacingInput {
  width: 20px;
  height: 20px;
  font-weight: 600;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;
  position: absolute;
  font-size: 0.8rem;
  background: transparent;
  border: none;
  color: #212529;
  text-align: center;
}

.spacingInput:focus{
  outline: 0;
  background-color: rgba(222, 227, 231, 0.63);
}

.leftInput {
  left: 2px;
}

.rightInput {
  right: 2px;
}

.topInput {
  top: 2px;
}

.bottomInput {
  bottom: 2px;
}

.noProperty {
  position: absolute;
  font-weight: 800;
}

.noPropLeft {
  left: 12px;
}

.noPropRight {
  right: 12px;
}

.noPropTop {
  top: -1px;
}

.noPropBottom {
  bottom: 1px;
}

.fastSpacingContainer {
  margin: 1rem 0;
  display: flex;
  font-size: 0.8rem;
}

.verticalSpaceIcon {
  font-size: 1rem;
  color: #b1b1b1;
}

.borderRadiusIcon {
  font-size: 1.3rem;
  color: #b1b1b1;
}

.resizerIconTop {
  color: #b1b1b1;
  position: absolute;
  left: 0;
  top: -35px;
}

.resizerIconBottom {
  color: #b1b1b1;
  position: absolute;
  left: 0;
  bottom: -5.2px;
}

.fastSpacingContent {
  width: 1.6rem;
  position: relative;
  font-size: 1.8rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputContainer {
  padding: 0.22rem 0.3rem;
  border: 1px solid transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: fit-content;
}

.inputContainer:hover {
  border: 1px solid #b1b1b1;
}

.verticalSpacingInput {
  width: 25px;
  border: 0;
  text-decoration: 0;
  margin-left: 0.6rem;
}

.verticalSpacingInput::-webkit-outer-spin-button,
.verticalSpacingInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.verticalSpacingInput[type='number'] {
  -moz-appearance: textfield;
}

.turnRight {
  transform: rotate(90deg);
}

