.LoginSubmitButton {
  width: 100%;
  margin-bottom: 24px;
}

.LoginSignInText {
  text-align: center;
  font-weight: 600;
  font-size: 1.75rem;
  padding-bottom: 24px;
}

.LoginExternalLink {
  color: #6ea8fe !important;
  font-size: 0.875em;
}

.ButtonSpinner {
  margin-right: 10px;
}

.DisabledButtonSpinner {
  display: none;
}

.PasswordInputDetails {
  display: flex;
  justify-content: space-between;
  padding-right: 0;
  padding-left: 0;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}

.PasswordInputDetails .checkbox {
  font-size: 0.875em;
  margin-top: 0;
}

.PasswordInputDetails .LoginForgotPasswordLink {
  margin-top: 0;
  color: #6ea8fe !important;
}

.loginPlaceholder::placeholder {
  color: #5e6063;
}

.loginPlaceholder::-webkit-textfield-decoration-container {
  display: none;
}

.loginPlaceholder::-ms-reveal {
  display: none;
}