/* Common styles */

.Title {
  color: #adb5bd;
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 32px;
}

/* ---------- Step1 ---------- */
.InputWrapper {
  position: relative;
}

.TagInputWrapper {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 5px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  cursor: text;
}

.TagsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5em;
}

.TagItem {
  background-color: var(--bs-body-color);
  color: var(--bs-body-bg);
  display: inline-block;
  font-weight: 500;
  white-space: nowrap;
  border-radius: 20px;
  padding-left: 10px;
  max-width: 100%;
}

.TagItem .DeleteTag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 20px;
  margin-left: 6px;
}

.TagInput {
  border: none;
  border-radius: 6px;
  max-width: 100%;
  width: 100%;
  height: 2rem;
  padding: 0.5rem;
}

.StepWrapper {
  height: auto;
  width: 1120px;
  padding-top: 1rem;
  position: relative;
}

@media (max-width: 1470px) {
  .StepWrapper {
    width: 720px;
    height: 50%;
  }
}

@media (max-width: 840px) {
  .StepWrapper {
    width: 400px;
    height: 20%;
  }
}

@media (max-width: 532px) {
  .StepWrapper {
    width: 300px;
    height: 20%;
  }
}

@media (max-width: 432px) {
  .StepWrapper {
    width: 200px;
    height: 20%;
  }
}

.ProgressBar {
  width: 100%;
  margin-left: 64px;
}

.ProgressBarWrapper {
  width: calc(100% - 64px);
}
