.IndexWrapper {
  margin-left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px
}

.IndexWrapper:hover {
  cursor: pointer;
}

.SelectedIndex {
  border-radius: 5px;
}

.IndexDetailsWrapper {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center
}

.KeyValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px;
  border: 1px solid transparent;
}

.Key {
  margin-right: 8px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
