.mainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 100ms ease-in;
  padding: 0.5rem 1rem;
  padding-left: 24px;
}

.containerTitle {
  height: 2rem;
  width: 50%;
  display: flex;
  align-items: center;
}

.containerMimeType {
  display: flex;
  justify-content: center;
  width: 7rem;
}
