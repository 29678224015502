.containerMain {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.containerOne {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleContainerOne {
  font-weight: 300;
  font-size: 40px;
}

.containerTwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.406rem;
}

.inputApp {
  width: 21.75rem;
}

.containerOrder {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.orderby {
  line-height: 0.6rem;
  margin-right: 0.3rem;
}

.filterButton {
  margin-left: 0.3rem;
}

.containerProject {
  height: 55vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 2rem;
  justify-content: center;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ErrorAlertWrapper {
  max-width: 600px;
}
