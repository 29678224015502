.ReturnTypeCombo {
  flex-grow: 1;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
  color: var(--bs-body-color);
  border-radius: var(--bs-border-radius);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: var(--bs-border-width) solid var(--bs-border-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: var(--bs-body-bg);
  position: relative;
}

.ReturnTypeComboInput {
  color: var(--bs-body-color);
  width: auto;
  max-width: 100px;
  text-align: center;
  border: none;
  background: transparent;
  overflow: hidden;
}

.ReturnTypeComboInput:focus-visible,
.ReturnTypeComboInput:focus {
  outline: none;
}

.ReturnTypeComboOptions {
  position: absolute;
  background-color: var(--bs-body-bg);
  top: 37px;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 150px;
  width: auto;
  padding-left: 0;
}

.ReturnTypeComboOption {
  font-weight: normal;
  display: block;
  min-height: 1.2em;
  padding: 0 2px 1px;
  white-space: nowrap;
}

.ReturnType {
  display: flex;
  align-items: center;
}

.ReturnTypeListCheck {
  display: flex;
}

.securitySelector {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 1rem;
}

.securitySelector label {
  margin-bottom: 8px;
}

.securitySelector .options {
  display: flex;
}

.securitySelector .options button {
  display: flex;
  padding: 15px;
  background-color: #0d6efd;
  color: #fff;
  margin: 0;
  border-radius: 0;
  height: 2.5rem;
  width: max-content;
  align-items: center;
}

.securitySelector .options button:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.securitySelector .options button:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.securitySelector .options button.selected {
  background-color: #0a58ca;
  color: #fff;
}

.securityRoles {
  width: 10rem;
}

.viewRoles {
  max-height: 8rem;
  width: 7rem;
  overflow: auto;
  margin-top: 10px;
}
