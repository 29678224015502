.Badge {
    font-family: SourceCodePro;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    width: 48px;
    font-size: 16px;
    padding: 8px 16px;
    margin-right: 8px;
    border-radius: 5px;
    white-space: break-spaces;
}
.wrapMethodAndPath {
    display: flex;
    align-items: center;
    padding: 0.7rem;
}

.containerPathDesc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.footerTag {
    text-align: center;
    padding: 4px;
    border-radius: 5px;
    margin-left: 5px;
    font-size: 11px;
    font-weight: bolder;
    background-color: #2dc8ee;
    color: black;
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
}
.itemIcons {
    background-color: #9fa6b2;
    padding: 3px 10px;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 1rem;
    border: 0;
}
