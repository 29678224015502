.SocialButton {
  text-align: center;
  border: none;
  border-radius: 1.25rem;
  /* height / 2 */
  height: 2.5rem;
  width: 2.5rem;
}

.SocialButton:hover {
  transform: scale(1.2);
  transition: ease-in-out 0.1s;
}

.SocialSignInButtons {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.SocialSignInButtons .SocialButton:not(:last-child) {
  margin-right: 24px;
}

.SocialButtonGoogle {
  font-size: 18px;
  border: 1px solid black;
  background-color: white;
  background: conic-gradient(from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  background-clip: text;
  color: transparent;
  -webkittext-fill-color: transparent;
}

.SocialButtonGitHub {
  font-size: 18px;
  background-color: black;
  color: white;
}

@media (max-width: 480px) {
  .SocialSignInButtons {
    display: flex;
    padding: 0px;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
}