.WizardProgressWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.WizardProgress {
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;
}

.StepIconsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 12px;
}

.StepIconDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  max-width: 32px;
}

.SvgCirclesWrapper {
  display: flex;
  flex-direction: column;
}

.CirclesWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -32px;
}

.SvgCircleDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  z-index: 2;
}

.SvgLineDiv {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 32px);
  height: 32px;
}

.StepTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
}

.StepTitleDiv {
  display: none;
}

.SvgLine {
  width: 100%;
}

.SvgCircle {
  width: 32px;
  height: 32px;
}

@media only screen and (min-width: 768px) {
  .StepTitleDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: bold;
    font-size: 14px;
    width: 110px;
    min-width: 110px;
    max-width: 110px;
  }

  .StepIconDiv {
    width: 110px;
    min-width: 110px;
    max-width: 110px;
  }

  .SvgCircleDiv {
    width: 110px;
    min-width: 110px;
    max-width: 110px;
  }

  .SvgLineDiv {
    width: calc(100% - 110px);
  }
}

@media only screen and (min-width: 1200px) {
  .StepTitleDiv {
    font-size: 20px;
    width: 160px;
    min-width: 160px;
    max-width: 160px;
  }

  .StepIconDiv {
    width: 160px;
    min-width: 160px;
    max-width: 160px;
  }

  .SvgCircleDiv {
    width: 160px;
    min-width: 160px;
    max-width: 160px;
  }

  .SvgLineDiv {
    width: calc(100% - 160px);
  }
}

.GrayCircle svg {
  fill: var(--bs-dark-border-subtle);
}

.BlueCircle svg {
  fill: #0d6efd;
}

.DarkCircle svg {
  fill: var(--bs-emphasis-color);
}

.GrayElement {
  color: var(--bs-dark-border-subtle);
}

.BlueElement {
  color: #0d6efd;
}

.DarkElement {
  color: var(--bs-emphasis-color);
}