.errorMsg {
  color: #d9534f;
  font-weight: 600;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
}