.newAppModalTitle {
  font-weight: 300;
  font-size: 16px;
}

.dialogSaveButton {
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.titleInvite {
  background-color: #f8f9fa;
}

.ButtonSpinner {
  margin-right: 10px;
}

.DisabledButtonSpinner {
  display: none;
}

/* @media (min-width: 450px) {
  .col-sm-10 {
    flex: 0 0 auto;
    width: 100%;
  }
} */

.containerModal {
}

.containerButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

@media only screen and (max-width: 480px) {
  .containerModal {
    width: 380px;
    height: 800px;
  }
}
