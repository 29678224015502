#crud-tag-dropdown {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
}

#crud-tag-dropdown.show {
    border: none;
}

#crud-entity-dropdown {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
}

#crud-entity-dropdown.show {
    border: none;
}