.wizardContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0px !important;
  overflow: auto;
}

/* .wizardContainer > div:first-of-type {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.wizardContainer > div:first-of-type > form {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
} */
/* 
.WizardTitle {
  position: relative;
  font-size: 24px;
  font-weight: 300;
  padding-bottom: 16px;
} */

.Title {
  color: #adb5bd;
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-height: 768) {
  .wizardContainer {
    height: 100vh;
  }
}
