.WizardProgressBar {
  width: 100%;
  /* border-top: 1px solid var(--bs-border-color); */
  background-color: inherit;
  padding: 12px 16px;
  border-radius: 5px;
}

.WizardProgressBarWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
