.mainContainer {
  width: 100%;
  height: calc(100vh - 60px);
  display: grid;
  place-items: center;
}

.image {
  position: absolute;
  transform: scale(0.5, 0.5);
  width: 32vw;
  height: 70vh;
  opacity: 1;
  top: -16vh;
  left: -7vw;
}

.growing {
  animation: growing-item 2000ms;
  animation-iteration-count: infinite;
}

@keyframes growing-item {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

.ellipsis {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis 2s infinite;
  -moz-animation: ellipsis 2s infinite;
}
@keyframes ellipsis {
  from {
    width: 2px;
  }
  to {
    width: 12px;
  }
}
@keyframes ellipsis {
  from {
    width: 2px;
  }
  to {
    width: 12px;
  }
}
