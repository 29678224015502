.FunctionsGroupWrapper {
  margin-bottom: 10px;
  overflow-y: scroll;
  max-height: calc(100vh - 290px);
  padding-bottom: 20px;
}

/* ---------- Header ---------- */
.FunctionsGroupHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.HeaderTitle {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 400;
  width: 250px;
}

.FunctionsGroupButtonArrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: auto;
  padding: 8px 0px;
}

.EditFunctionButton {
  white-space: nowrap;
  margin-right: 16px;
}

.NewFunctionButton {
  white-space: nowrap;
  margin-right: 16px;
}

.FixedIconWidth {
  font-size: 0.7rem;
  min-width: 18px;
}

.FunctionsListWrapper {
  margin-top: 8px;
  margin-bottom: 32px;
}

/* ---------- Function ---------- */
.FunctionWrapper {
  cursor: pointer;
  border-radius: 6px;
  padding: 8px;
}

.FunctionWrapper:not(:last-child) {
  margin-bottom: 16px;
}

.FunctionDescription {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 16px;
}

.Badge {
  color: white;
  min-width: 250px;
  height: 28px;
  font-size: 12px;
  font-weight: 700;
  padding: 8px 16px;
  margin-right: 8px;
  border-radius: 5px;
  white-space: break-spaces;
}

.NativeBadge {
  min-width: 90px;
}

.BadgeBackground {
  background-color: #495057 !important;
}

.Details {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
}

.LeftSideWrapper {
  display: flex;
  flex-direction: row;
}

.containerObj {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--bs-border-color);
  cursor: pointer;
  margin-bottom: 1rem;
  height: fit-content;
  padding: 8px 16px;
  gap: 0.5rem;
}

.containerObj:hover {
  background-color: rgba(77, 86, 95, 0.5);
}

.headObj {
  width: 20%;
  padding: 3px 0;
  text-align: center;
  font-weight: 600;
  color: var(--bs-emphasis-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
}

.bodyObj {
  width: 80%;
  padding-left: 1.2rem;
  color: #919599;
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footerObj {
  width: 20%;
  padding-left: 1.2rem;
  display: flex;
  justify-content: flex-end;
}

.footerTag {
  text-align: center;
  padding: 4px;
  border-radius: 50rem;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: #2dc8ee;
  color: black;
  width: 6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemIcons {
  background-color: #9fa6b2;
  border: 1px solid var(--bs-border-color);
  padding: 7px 10px;
  border-radius: 8px;
  cursor: pointer;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapperIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.itemIcons:hover{
  color: #fff !important;
}