.PageWrap {
  background-color: #343a40;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.ResetPasswordContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3);
  min-width: fit-content;
  padding-top: 50px;
}

.LogoContainer {
  width: 480px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.FormCard {
  width: 100%;
  border-radius: 10px;
  background-color: #2b3036 !important;
  padding-left: 7rem;
  padding-right: 7rem;
  padding-top: 48px;
  padding-bottom: 48px;
}

.ExocodeLogo {
  padding-bottom: 1rem;
}

.placeholder::placeholder {
  color: #5e6063;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.alert {
  position: fixed;
  margin-top: 15px;
  margin-left: 15px;
  width: 30%;
  z-index: 999999;
  cursor: pointer;
  transition: transform 100ms ease-in;
  animation: fadeIn 4s;
}

.alert:hover {
  transform: scale(1.02);
}

@media (max-width: 767px) {
  .LogoContainer {
    width: 300px;
  }
}

@media (max-width: 690px) {
  .ResetPasswordContainer{
    min-width: unset !important;
    width: 100%;
  }

  .LogoContainer {
    width: 280px;
  }

  .FormCard {
    width: 100%;
    border-radius: 10px;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media (max-width: 480px) {
  .LoginFormCard {
    width: 95%;
    padding-left: 4rem;
    padding-right: 4rem;
    width: 98%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
