.colorIcon:hover{
  transform: scale(1.4);

}

.colorIcon {
  transition: all ease-in-out 0.05s;
  width: 1.2rem;
  border-radius: 50%;
  height: 1.2rem;
  position: relative
}

.selectedIcon{
  position: absolute;
  top: -4px;
  right: -4px;
}