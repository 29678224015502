.functionBody {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.FunctionBody {
  max-height: 80vh;
  padding-inline: 20px;
}

.FunctionBody::-webkit-scrollbar {
  width: 8px;
}

.FunctionBody::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 10px;
}

.FunctionBody::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}

.FunctionBody::-webkit-scrollbar-track {
  background: transparent;
}

.actionWrapper {
  background: transparent;
  text-align: center;
  min-height: 50px;
  max-height: fit-content;
}

.actionWrapper:focus-visible {
  outline: transparent;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
}
