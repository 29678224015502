.indexContainer {
  padding: 1rem;
}

.indexListContainer {
  width: 100%;
  padding: 0;
}

.indexListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
  font-weight: 600;
}

.containerIndexItem {
  position: relative;
  display: inline;
  cursor: pointer;
  max-width: 100px;
  font-weight: 600;
  font-size: small;
}

.containerIndexItem > p {
  font-size: 0.8rem;
  position: relative;
  display: inline;
  text-overflow: 'ellipsis';
  overflow: 'hidden';
  max-width: 100%;
}

.containerIndexItem > i {
  display: inline;
  position: relative;
  right: -4px;
  font-size: 0.6rem;
  font-weight: bold;
  color: #5196fe;
  top: -8px;
}

.containerIndexItem > b {
  display: inline;
  position: relative;
  left: -25px;
  font-size: 0.8rem;
  color: rgb(110, 110, 110);
  top: 5px;
  letter-spacing: 2px;
}

.indexListItem p {
  margin: 0;
  padding: 0;
}

.listItemActions {
  display: flex;
  align-content: center;
  justify-content: center;
  transition: ease-in-out 0.2s;
}

.listItemActions i:hover {
  cursor: pointer;
}

.indexInput {
  font-size: 1rem;
  padding: 0 0 0 0.5rem;
  width: 100%;
}

.newIndexContainer {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: column;
}

.indexContainerActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.newIndexButton {
  padding: 0.1rem 0.5rem !important;
  margin-left: 0.5rem;
}

.newIndexButton p {
  margin: 0;
}

.hidingScrollBarIndexes::-webkit-scrollbar {
  display: none;
}
