.modal {
  padding: 3rem;
  padding-bottom: 0.3rem;
}

.containerBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerCheckBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.containerCheckBox > p {
  color: #000000;
  font-size: 20px;
}

.checkbox {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 20%;
}

.preview {
  margin-left: 2rem;
  font-weight: 400;
  font-size: 20px;
}

.containerListOfPreview {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  background-color: #f8f9fa;
  overflow-x: auto;
  margin-bottom: 2.8rem;
  border-radius: 4px;
}

.containerFormModal {
  margin-right: 1rem;
  margin-left: 1rem;
}

.containerFormOne {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
}

.subContainerFormOne {
  display: flex;
  width: 50%;
}

.subContainerFormTwo {
  display: flex;
  width: 50%;
}

.infoInput {
  padding: 0;
  margin: 0;
  font-size: small;
}

.inputItem {
  padding: 0.5rem;
  width: 90%;
}

.formInpt {
  width: 100%;
}

.inputItem:focus {
  outline-color: #0d6efd;
}

.labelForm {
  display: inline;
  padding-right: 1rem;
}

.labelFormSelect {
  display: inline;
  width: 5rem;
}

.select {
  padding: 0.5rem;
  width: 90%;
}

.containerFormTwo {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.footerModal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
  margin-left: 1rem;
}

.checkboxFooter {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.checkboxFooter > p {
  margin-left: 0.6rem;
}

.containerCheckboxFooter {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 25%;
}

.renderingValues {
  display: flex;
  align-items: center;
  padding: 0;
}

/* ---------------- */

.container {
  display: flex;
  width: 51.438rem;
  height: 35rem;
  padding: 0;
}

.nestedItemsContainer {
  width: 19.625rem;
  background-color: #ffffff;
  overflow: auto;
}

.formContainer {
  margin-top: 1.841rem;
}

.formEditPlace {
  flex-grow: 1;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 2rem;
}

.titleIntoEditPlace {
  padding-right: 4.688rem;
  margin-bottom: 1.5rem;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

.dropItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navHeader {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  margin-top: 1.5rem;
  font-size: 16px;
  margin-bottom: 1.4rem;
}

.ListGroup:first-child {
  border-top: 0px;
  border-right: 0px;
}

.ListGroup:last-child {
  border-bottom: 0px;
  border-right: 0px;
}

.TreeComponent {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.iconsInTheLine:hover {
  background-color: rgba(239, 239, 240, 0.7);
  border-radius: 9999px;
  cursor: pointer;
}

.line {
  margin: 0px;
  padding: 0px;
  padding-bottom: 1rem;
  margin-top: 0.5rem;
  padding-right: 1rem;
  width: 85%;
}

.containerButton {
  display: flex;
  justify-content: flex-end;
}

.plusIcon {
  border-radius: 999px;
  margin-bottom: 0.5rem;
}

.plusIcon:hover {
  background-color: #f5f5f5;
}

.containerPlus {
  display: flex;
  justify-content: center;
}

.containerPresentation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
