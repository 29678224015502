.containerButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rolesContainer {
  min-height: 160px;
  height: 50vh;
  margin-top: 1rem;
  padding: 0.5rem;
  overflow: visible;
  overflow-y: auto;
}

.roleItem {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 24px;
  align-items: center;
}

.roleItem:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.alignVertical {
  display: flex;
  align-items: center;
}
