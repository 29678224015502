.containerMain {
  padding-bottom: 1.5rem;
  padding-top: 0.3rem;
}

.containerMain p {
  margin-bottom: 0.5rem;
}

.iconButton {
  font-size: 1.2rem;
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabledRow {
  opacity: 0.5;
}

.infoCol {
  flex-direction: column;
}

.hoverOpacity:hover {
  opacity: 0.7;
}

.orgIcon {
  width: 10%;
}

.orgIcon:hover {
  opacity: 0.7;
}

.avatarIcon {
}

.mobileVersion {
}

.mobileVersionContainerAppAvatarInfo {
}

@media (min-width: 768px) {
  .infoCol {
    flex-direction: row;
    align-items: center;
  }
}

.firstContainerMobile {
  display: flex;
  justify-content: space-between;
}

.containerIconMobile {
  display: flex;
  justify-content: flex-end;
}

.secondContainerMobile {
  display: flex;
  justify-content: space-between;
}
