.ReturnTypeCombo {
    flex-grow: 1;
    padding: .375rem 0.75rem .375rem .75rem;
    color: var(--bs-body-color);
    border-radius: var(--bs-border-radius);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: var(--bs-border-width) solid var(--bs-border-color);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color: var(--bs-body-bg);
    position: relative;
    width: 200px;
}

.ReturnTypeComboInput {
    color: var(--bs-body-color);
    width: auto;
    max-width: 100px;
    text-align: center;
    border: none;
    background: transparent;
    overflow: hidden;
}

.ReturnTypeComboInput:focus-visible, .ReturnTypeComboInput:focus {
    outline: none;
}

.ReturnTypeComboOptions {
    position: absolute;
    background-color: var(--bs-body-bg);
    top: 37px;
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 150px;
    width: auto;
    padding-left: 0;
}

.ReturnTypeComboOption {
    font-weight: normal;
    display: block;
    min-height: 1.2em;
    padding: 0 2px 1px;
    white-space: nowrap;
}