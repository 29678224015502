.ReturnTypeCombo {
  flex-grow: 1;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
  color: var(--bs-body-color);
  /* border-radius: var(--bs-border-radius); */
  /* border-top-right-radius: 0;
  border-bottom-right-radius: 0; */
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: var(--bs-body-bg);
  position: relative;
}

.ReturnTypeComboInput {
  color: var(--bs-body-color);
  width: auto;
  max-width: 100px;
  text-align: center;
  border: none;
  background: transparent;
  overflow: hidden;
  border-radius: var(--bs-border-radius);
}

.ReturnTypeComboInput:focus-visible,
.ReturnTypeComboInput:focus {
  outline: none;
}

.ReturnTypeComboOptions {
  position: absolute;
  background-color: var(--bs-body-bg);
  top: 37px;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 150px;
  width: auto;
  padding-left: 0;
}

.ReturnTypeComboOption {
  font-weight: normal;
  display: block;
  min-height: 1.2em;
  padding: 0 2px 1px;
  white-space: nowrap;
}

.ReturnType {
  display: flex;
  align-items: center;
}

.ReturnTypeListCheck {
  display: flex;
  margin-left: 0.5rem;
  border-radius: 0px;
}
