.mainContainer {
  padding: 0px;
  margin: 0px;
  min-height: 160px;
  overflow-y: auto;
}

.containerButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.containerRow {
  min-height: 160px;
  height: 50vh;
  margin-top: 1rem;
  padding: 0.5rem;
  overflow: visible;
  overflow-y: auto;
}

.alignVertical {
  display: flex;
  align-items: center;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
