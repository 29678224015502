.Avatar {
  font-weight: 400;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: linear-gradient(
    135deg,
    rgba(2, 0, 36, 0.25) 0%,
    rgba(9, 9, 121, 0.25) 35%,
    rgba(0, 212, 255, 0.25) 100%
  );
}
