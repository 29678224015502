.ChangePasswordText {
  text-align: center;
  font-weight: 600;
  font-size: 1.75rem;
  padding-bottom: 24px;
}

.passFieldsContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  width: 500px;
}

.PasswordInputDetails {
  display: flex;
  justify-content: space-between;
  padding-right: 0;
  padding-left: 0;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}

.PasswordInputDetails .checkbox {
  font-size: 0.875em;
  margin-top: 0;
}

.ButtonContainer {
  width: 100%;
  margin-bottom: 24px;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.Buttons {
  margin-left: 1rem;
}

.ButtonSpinner {
  margin-right: 10px;
}

.DisabledButtonSpinner {
  display: none;
}

.inputGroup {
  background-color: white;
}

.MainInputGroup{
  max-width: 400px;
}

@media (max-width: 480px) {
  .ButtonContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .Buttons {
    margin-top: 1rem;
    margin-left: 0px;
    width: 100%;
  }
}