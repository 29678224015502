.modulesContainer {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: white;
  padding: 1rem;
}

.moduleListWrapper {
  opacity: 0.9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
