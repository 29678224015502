.greenBall {
  width: 20px;
  height: 20px;
  border-radius: 999px;
  background-color: #78c964;
}

.orangeBall {
  width: 20px;
  height: 20px;
  border-radius: 999px;
  background-color: #ff8c00;
}

.redBall {
  width: 20px;
  height: 20px;
  border-radius: 999px;
  background-color: #be5959;
}