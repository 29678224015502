.viewItem:hover {
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.viewItemContainer {
  font-size: 14px;
}

.dropdownButton::after {
  display: none !important;
}

.rootDraggableArea {
  height: calc(100% - 64px);
  width: 100%;
}

.invalidTextInput {
  border-color: red !important;
}

.invalidTextInput:focus {
  box-shadow: 0 1px 1px red inset, 0 0 8px red;
  outline: 0 none;
}

.dropdownButton::after {
  display: none !important;
}

.disabledFolderItem {
  pointer-events: none;
  opacity: 0.5;
}
