.ColumnsListWrapper {
  min-width: 40%;
  margin-right: 16px;
  font-size: 0.875rem;
  max-height: 100%;
  overflow-y: auto;
}

.ColumnWrapper {
  margin-left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px
}

.ColumnWrapper:hover {
  cursor: pointer;
}

.SelectedColumn {
  border-radius: 5px;
}

.ColumnButtonWrapper {
  display: flex;
  align-items: center;
}

.ColumnButton {
  margin: 0 8px;
}

.ColumnReorderButton:hover {
  cursor: grab;
}

.ColumnDetailsWrapper {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center
}

.KeyIcon {
  color: yellow;
}

.KeyValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px;
  border: 1px solid transparent;
}

.Key {
  margin-right: 8px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Value {
  margin-right: 8px;
  max-width: 150px;
  font-size: 13px;
}
