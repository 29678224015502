.sidebarContainer {
  padding: 0.5rem;
  width: 100%;
  height: 100%;
}

.sidebarHeader {
  padding: 0.5rem;
  color: rgb(94, 94, 94);
}

.topContent {
  display: flex;
  align-items: center;
  justify-content: left;
}

.icon {
  padding: 0.5rem;
  font-size: 2rem;
  margin-right: 0.5rem;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.1rem;
  font-weight: bold;
  padding-right: 0.25rem;
}

.bottomContent {
  display: flex;
}

.editIcon {
  display: flex;
  padding-left: 0.5rem;
}

.entityDescription {
  max-height: 4.5em;
  overflow: hidden;
  text-overflow: ellipsis;
}
