.ScheduledsGroupWrapper {
  margin-bottom: 10px;
}

/* ---------- Header ---------- */
.ScheduledsGroupHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 16px;
}

.HeaderTitle {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 400;
  width: 250px;
}

.ScheduledsGroupButtonArrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 250px;
}

.EditScheduledButton {
  white-space: nowrap;
  margin-right: 16px;
}
.NewScheduledButton {
  white-space: nowrap;
  margin-right: 16px;
}

.FixedIconWidth {
  font-size: 0.7rem;
  min-width: 18px;
}

.ScheduledsListWrapper {
  margin-top: 8px;
  margin-bottom: 32px;
}

/* ---------- Scheduled ---------- */
.ScheduledWrapper {
  cursor: pointer;
  border-radius: 5px;
  padding: 14px;
}

.ScheduledWrapper:hover {
  background-color: rgba(77, 86, 95, 0.5) !important;
}

.ScheduledWrapper:not(:last-child) {
  margin-bottom: 16px;
}

.ScheduledDescription {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 16px;
}

.Badge {
  color: white;
  min-width: 250px;
  height: 28px;
  font-size: 12px;
  font-weight: 700;
  padding: 8px 16px;
  margin-right: 8px;
  border-radius: 5px;
  white-space: break-spaces;
}

.NativeBadge {
  min-width: 90px;
}

.BadgeBackground {
  background-color: #495057 !important;
}

.Details {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
}

.LeftSideWrapper {
  display: flex;
  flex-direction: row;
}

.AlignRight {
  display: flex;
  justify-content: flex-end;
}

.itemIcons {
  background-color: #9fa6b2;
  border: 1px solid var(--bs-border-color);
  padding: 7px 10px;
  border-radius: 8px;
  cursor: pointer;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemIcons:hover{
  color: #fff !important;
}