.containerList {
  border: 1px solid red;
}

.line {
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.list {
  overflow: hidden;
  padding: 0 16px;
}

.item {
  border-bottom: 1px solid #e9ecef;
  padding-top: 1rem;
  padding-bottom: 0.7rem;
  cursor: pointer;
  padding-left: 2rem;
  margin-left: -15px;
  margin-right: 15px;
}

.itemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9ecef;
  /* padding-top: 0.4rem; */
  padding-bottom: 0.4rem;
  cursor: pointer;
}

.dotsTrashPlus {
  margin-right: 0.5rem;
  color: #ced4da;
}

.title {
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  color: black;
  word-break: break-all;
}

.editingTitle {
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  color: #f08080;
}
