@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  position: fixed;
  bottom: 0;
  /* margin-top: 15px; */
  margin-left: 15px;
  width: 30%;
  z-index: 999999;
  cursor: pointer;
  transition: transform 100ms ease-in;
  animation: fadeIn 4s;
}

.container:hover {
  transform: scale(1.02);
}
