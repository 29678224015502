.TagItem {
  background-color: var(--bs-body-color);
  color: var(--bs-body-bg);
  display: inline-block;
  font-weight: 500;
  white-space: nowrap;
  border-radius: 20px;
  padding: 0 10px 0 10px;

  user-select: none;
  cursor: pointer;
}

.TagItemViewOnly {
  background-color: var(--bs-body-color);
  color: var(--bs-body-bg);
  display: inline-block;
  font-weight: 400;
  font-size: x-small;
  white-space: nowrap;
  border-radius: 20px;
  padding: 0 6px 0 6px;
  margin-left: 5px;
}