.WidgetWrapper {
  min-width: 250px;
  min-height: 100px;
}

/* ----- StickyNoteWidget ----- */
.StickyNote {
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #fff3bf;
  border: 1px solid #ffe066;
  z-index: 1.5;
}

.ActivatedStickyNote {
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #ffe066;
  border: 1px solid #ffe066;
  z-index: 1.5;
}

.StickyNote textarea {
  width: 100%;
  height: calc(100% - 36px);
  border-radius: 8px;
  border-color: #fff3bf;
  resize: none;
  overflow: hidden;
  background-color: #fff3bf;
}

.ActivatedStickyNote textarea {
  width: 100%;
  height: calc(100% - 36px);
  border-radius: 8px;
  border-color: #ffe066;
  resize: none;
  overflow: auto;
  background-color: #ffe066;
}

.StickyNote:hover {
  background-color: #ffec99;
}

.StickyNote:hover textarea {
  border-color: #ffec99;
  background-color: #ffec99;
}

.StickyNoteProfileName {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: 500;
}

.StickyNoteInput {
  width: 100%;
  height: 100%;
}

.StickyNoteInput::-webkit-scrollbar {
  width: 1em;
}

.StickyNoteInput::-webkit-scrollbar-track {
  background-color: #fff3bf;
  border-radius: 6px;
}

.StickyNoteInput::-webkit-scrollbar-thumb {
  background-color: #fab005;
  border-radius: 6px;
}

.WidgetWrapper::-webkit-resizer {
  background-color: transparent;
  border-width: 4px;
  border-style: solid;
  border-color: transparent #fab005 #fab005 transparent;
}

.StickyNoteInput:focus {
  outline: none;
}

.StickyNoteDate {
  display: flex;
  align-items: center;
  color: #868e96;
  margin-left: 16px;
  font-size: 12px;
  line-height: 12px;
}
