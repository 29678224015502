.deleteButtonContainer {
  background-color: #dc4c64 !important;
  padding-right: 9px;
  padding-left: 9px;
}
.ParameterEditor {
  display: flex;
  align-items: center;
}

.AddParameterButton {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  text-align: center;
  align-self: center;
  width: fit-content;
  padding-top: 0.7rem;
  padding-bottom: 0.8rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  border-radius: 8px;
  background-color: #3b71ca;
  color: white;
  margin: 10px auto;
}
