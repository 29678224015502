.itemIcons {
  background-color: #9fa6b2;
  padding: 7px 10px;
  border-radius: 8px;
  cursor: pointer;
  height: 35px;
  width: 35px;
}

.itemIcons:hover {
  color: #fff !important;
}
