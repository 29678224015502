.elementExample {
  color: var(--bs-secondary-color) !important;
  height: 2.6rem;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
}

.addIconPlaceholder {
  position: absolute;
  top: 7px;
  left: 14px;
}

.removeIcon {
  position: absolute;
  right: 4px;
  top: -2px;
  color: var(--bs-secondary-color) !important;
  opacity: 0.4;
  font-size: 14px;
}

.removeIcon:hover {
  opacity: 1;
}