.container {
  margin-left: 0.2rem;
}

.svg {
  width: 30px;
  height: 30px;
  margin: 0px;
  padding: 0px;
}
