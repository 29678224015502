.large {
  width: 500px;
  height: 300px;
}

.modal {
  border-radius: 10px;
  float: left;
  margin: 10px;
}

.disabled {
  opacity: 0.8;
  pointer-events: none;
}

.browserControls {
  height: 25px;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #e6ecef;
  color: #bec4c6;
  font-size: 0.7rem;
  user-select: none;
}
