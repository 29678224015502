.newOrgModalTitle {
  font-weight: 300;
  font-size: 1.25rem;
}

.dialogSaveButton {
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ButtonSpinner {
  margin-right: 10px;
}

.DisabledButtonSpinner {
  display: none;
}

/* @media (min-width: 450px) {
  .col-sm-10 {
    flex: 0 0 auto;
    width: 100%;
  }
} */
