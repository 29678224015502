.appWrapper {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  border-radius: 5px;
}

.resumeWrapper {
  display: flex;
  justify-content: start;
  align-items: center;
}

.appDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0.5rem;
}

.appIcon {
  margin-right: 1rem;
  padding: 0.5rem;
}

.appData {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.appData h3 {
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0 0 0 0.5rem;
}

.appData p {
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
  font-weight: 700;
}

.appDescription {
  overflow: hidden;
  max-width: 50rem;
}

.appDescription p {
  margin: 1rem 0 0 0;
  font-size: 1rem;
}

.appAction {
  display: flex;
  justify-content: end;
  align-items: center;
}

.appAction > button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  font-size: 1.1rem;
}

.downloadCodeButton {
  height: 50px;
  margin-left: 0.5rem;
  -webkit-box-shadow: 0px 0px 20px -3px rgba(13, 110, 253, 1);
  -moz-box-shadow: 0px 0px 20px -3px rgba(13, 110, 253, 1);
  box-shadow: 0px 0px 20px -3px rgba(13, 110, 253, 1);
}

.actionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  gap: 16px;
}

.overFlowText {
  overflow: hidden;
  width: 68%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.firstContainer {
  display: flex;
  width: 100%;
}

.firstRow {
  border-radius: 5px;
}

.nameProject {
  white-space: nowrap;
  width: 72%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ownerName {
  white-space: nowrap;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 991px) {
  .overFlowText {
    width: 30%;
  }
}

@media only screen and (max-width: 767px) {
  .overFlowText {
    width: 20%;
  }
}

@media only screen and (max-width: 480px) {
  .firstContainer {
    flex-direction: column;
  }
  .resumeWrapper {
    margin-bottom: 1rem;
  }

  .overFlowText {
  }
}

@media only screen and (max-width: 768px) {
  .appDetails {
    width: 100%;
  }

  .appWrapper {
    padding-top: 1rem;
  }

  .appAction {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 769px) {
  .appDetails {
    width: calc(100% - 96px);
  }
}
