.avatar {
  font-weight: 500;
  color: white;
  background-color: #479f76;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  font-size: 16px;
}

.container {
  margin: 9px;
  transition: all 0.3s ease;
}

.disabledContainer {
  margin: 9px;
  opacity: 0.5;
}

.container:hover {
  cursor: pointer;
  border-radius: 10px;
  background-color: #44494e44;
}

.roleMembers {
  border: none;
  width: auto;
}

.iconsMember {
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
}

.iconButton {
  background-color: transparent;
  border: none;
  padding: 0;
}

.iconButton:hover {
  background-color: transparent !important;
  color: #fff !important;
  border: none;
}

.membersListWrapper {
  max-height: 32vh;
  min-height: 20vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.membersListWrapperSmall {
  height: 7.5vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.infoCol {
  flex-direction: column;
}

.infoColModal {
  flex-direction: column;
}

@media (min-width: 992px) {
  .infoColModal {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .infoCol {
    flex-direction: row;
    align-items: center;
  }
}
