.errorMsg {
  color: #d9534f;
  font-weight: 600;
}

.switchGroupItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* 
.footerObj {
  width: 20%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.2rem;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
} */

.footerTag {
  border-radius: 10px;
  font-size: 10px;
  font-weight: bold;
  background-color: #2dc8ee;
  color: black;
  width: 60px;
  text-align: center;
  margin: 5px;
}

.pageOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  max-height: 550px;
  overflow: auto;
}

.Card {
  margin-right: 32px;
  margin-bottom: 32px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 200px;
  cursor: pointer;
  filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.25));
}

.Card:hover {
  border: 2px solid var(--bs-emphasis-color);
}

.CardTitle {
  border-radius: 3px 3px 0 0;
  font-size: 16px;
  font-weight: 400;
  color: white;
  background-color: #339af0;
  padding: 10px 16px;
  margin-bottom: 0;
  height: 40px;
}

/* .Card:hover .CardTitle {
  background-color: #0dcaf0;
} */

.CardSelected {
  border: 2px solid var(--bs-emphasis-color);
  background-color: #ced4da;
  color: #333333;
  font-weight: 600;
}

.CardDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.CardFooter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  flex-direction: row;
}

.SelectsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.SelectWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
