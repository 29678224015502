/* Common styles */
.StepWrapper {
  z-index: 1;
}

.Title {
  text-align: center;
  font-size: 26px;
  font-weight: 300;
  padding-bottom: 16px;
}

/* ---------- Step0 ---------- */
.SearchWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
}

.Search {
  width: 350px;
  min-width: 180px;
  margin-right: 16px;
}

.FilterWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.FilterOrder {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.OrderLabel {
  display: flex;
  align-items: center;
  white-space: pre;
  margin-right: 8px;
}

.FilterButton {
  margin-left: 8px;
}

/* Cards */
.CardsWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.Card {
  margin-right: 32px;
  margin-bottom: 32px;
  border-width: 0px;
  border-radius: 4px;
  height: 250px;
  width: 200px;
  cursor: pointer;
  filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.25));
}

.Card:hover {
  border: 1px solid white;
}

.Card:hover {
  border-style: solid;
  border-width: 1px;
  border-color: white;
}

.CardTitle {
  border-radius: 3px 3px 0 0;
  font-size: 16px;
  font-weight: 400;
  color: white;
  background-color: #339af0;
  padding: 10px 16px;
  margin-bottom: 0;
  height: 40px;
}

.Card:hover .CardTitle {
  background-color: #a5d8ff;
}

.CardText {
  font-size: 14px;
  font-weight: 300;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 110px;
}

.CardTags {
  font-size: 10px;
  font-weight: 300px;
  background: #74ed94;
}

.CardFooter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}