.searchBar:focus {
  border: none;
  box-shadow: none;
}

.searchIcon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.categoriesFilter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  font-size: 1rem;
  font-weight: 100;
  color: #0d6efd;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
}

.categoriesFilter:hover {
  cursor: pointer;
}
.clearCategories {
  font-size: 0.8rem;
}

.clearCategories:hover {
  cursor: pointer;
  transform: scale(1.3);
  transition: transform 0.2s ease;
}

.textHover:hover {
  color: #fff !important;
}

.categoriesText:hover {
  color: #3b86f8 !important;
}

.emptycard {
  width: 200px;
}
