.dropdownMenu {
}

.dropdownMenu li {
  position: relative;
}
.dropdownMenu .dropdownSubmenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}
.dropdownMenu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}
.dropdownMenu > li:hover > .dropdownSubmenu {
  display: block;
}

.openedItem {
  transform: rotate(90deg);
}

.smoothRotate {
  width: 1.25em;
  line-height: 0;
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
  display: inline-block;
}

.hidden {
  opacity: 0;
}

.overlook {
  display: none;
}

.menuHideIcon {
  position: absolute;
  z-index: 10;
  top: 40vh;
  right: -24px;
  background-color: #c4c4c4;
  width: 25px;
  text-align: center;
  border-radius: 50%;
  color: #333;
  opacity: 0.6;
  cursor: pointer;
  display: none;
}

.menuHide {
  display: block;
}

/* Query do hide the side menu when in mobile */
@media screen and (max-width: 400px) {
  .menuHideIcon {
    display: block;
  }

  .menuHide {
    display: none;
    position: absolute;
  }

  .sideMenuWrapper {
    position: relative;
  }

  .menuHide {
    position: relative;
  }
}

.dropdown-menu li {
  position: relative;
}
.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: 7px;
}
.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}
.dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
}
