.DeleteButton {
  margin-right: 10px;
}

.DeleteButton:hover {
  opacity: 0.4;
  cursor: pointer;
}

.CssClassesWrapper {
  max-height: 375px;
  width: 100%;
  overflow: auto;
}

.item:hover {
  opacity: 80%;
}
