/* Common styles */
.StepWrapper {
  height: auto;
  width: 1320px;
  position: relative;
  margin-top: 1rem;
}

@media (max-width: 1470px) {
  .StepWrapper {
    width: 720px;
    height: 50%;
  }
}

@media (max-width: 840px) {
  .StepWrapper {
    width: 400px;
    height: 20%;
  }
}

@media (max-width: 532px) {
  .StepWrapper {
    width: 300px;
    height: 20%;
  }
}

@media (max-width: 432px) {
  .StepWrapper {
    width: 200px;
    height: 20%;
  }
}

.Title {
  text-align: center;
  font-size: 26px;
  font-weight: 300;
  padding-bottom: 16px;
}

.ProgressBar {
  width: 100%;
  margin-left: 64px;
}

.ProgressBarWrapper {
  width: calc(100% - 64px);
}

/* ---------- Step1 ---------- */
.nameEntity {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Search {
  width: 350px;
  min-width: 180px;
  margin-right: 16px;
  margin-bottom: 2rem;
}

/* Cards */
.CardsWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
  margin-bottom: 9rem;
}

.CardsWrapperSummary {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 400px;
  font-size: larger;
}

.Card {
  margin-right: 32px;
  margin-bottom: 32px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  height: 150px;
  width: 200px;
  cursor: pointer;
  filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.25));
}

.Card:hover {
  border: 1px solid white;
}

.CardTitle {
  display: flex;
  gap: 5px;
  border-radius: 3px 3px 0 0;
  font-size: 16px;
  font-weight: 400;
  color: white;
  background-color: #339af0;
  padding: 10px 16px;
  margin-bottom: 0;
  height: 40px;
}

.Card:hover .CardTitle {
  background-color: #0dcaf0;
}

.CardText {
  font-size: 14px;
  font-weight: 300;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 110px;
}

/* ---------- Step2 ---------- */
.DataObjectsHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 0.5rem;
  margin-bottom: 35px;
  /* border: 1px solid red; */
}

.DataObjectHeaderClass {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.DataObjectHeaderNewFunc {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DataObjectsListWrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-evenly;
}

.ObjectListWrapper {
  overflow-y: scroll;
  max-height: calc(100vh - 370px);
  padding-bottom: 30px;
}

.Object {
  align-items: center;
  justify-content: center;
  margin-right: 32px;
  margin-bottom: 32px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  height: 50px;
  width: 80%;
  cursor: pointer;
  filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.25));
}

.ObjectName {
  width: 20%;
}

.ObjectDesc {
  width: 40%;
}

.ObjectBadge {
  height: min-content;
  width: 40px;
}

/* ---------- Step3 ---------- */
.ResumeListsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-row: span 2;
  overflow: auto;
}

.ResumeList {
  padding: 16px;
  margin-right: 40px;
  max-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ResumeListTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 32px;
}

.ResumeListItems {
  padding: 16px;
  white-space: pre;
}

.ResumeListItem {
  display: flex;
  align-items: center;
}

.ResumeListItem:not(:last-child) {
  margin-bottom: 16px;
}

.GreenIcon {
  color: #479f76;
}

.ResumeItemText {
  font-size: 20px;
  font-weight: 400;
  margin-left: 16px;
}

.containerTemplates {
  display: flex;
  height: 500;
}

.containerTemplates > * {
  margin-right: 1rem;
  cursor: pointer;
}

.imgLabelContainer {
  display: flex;
  flex-direction: column;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.25));
}

.imgStyles {
  width: 150px;
  margin-bottom: 0.5rem;
}

.FunctionsListWrapper {
  margin-top: 8px;
  padding-bottom: 50px;
  overflow-y: scroll;
  max-height: calc(100vh - 350px);
  margin-bottom: 10px;
}

.BodyListWrapper {
  overflow-y: scroll;
  max-height: calc(100vh - 355px);
  padding-bottom: 20px;
}

.FunctionWrapper {
  cursor: pointer;
  border-radius: 5px;
  padding: 8px;
}

.FunctionWrapper:not(:last-child) {
  margin-bottom: 16px;
}

.FunctionDescription {
  font-family: SourceCodePro;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  line-height: 16px;
}

.Badge {
  font-family: SourceCodePro;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  width: 100%;
  font-size: 12px;
  padding: 8px 16px;
  margin-right: 8px;
  border-radius: 5px;
  white-space: break-spaces;
}

.BadgeBackground {
  background-color: #495057 !important;
}

.BadgeBackgroundWhite {
  background-color: #f8f9fa !important;
  color: #495057;
}

.CodeFunctionDefinition {
  white-space: pre;
  background-color: transparent;
}

.Details {
  color: #82c91e;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
}

.FunctionComment {
  display: flex;
  flex-direction: row;
  margin-left: 16px;
}

.CommentSlashes {
  color: #82c91e;
  margin-right: 6px;
  font-size: 12px;
}

.SelectionWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid red; */
}

/* ---------- Step4 ---------- */
.Step5Title {
  font-size: 24px;
  font-weight: 900;
  line-height: 28px;
  margin-bottom: 32px;
}

.ListWrapper {
  list-style-type: disc;
  margin-left: 32px;
}

.EndpointsListWrapper {
  margin-top: 8px;
  padding-bottom: 20px;
  overflow-y: scroll;
  max-height: calc(100vh - 350px);
  margin-bottom: 10px;
}

.ListItemEmph {
  font-weight: 500;
  font-size: 24px;
}

.ListItemLight {
  font-weight: 400;
  font-size: 20px;
}

.DataObjectHeaderNewEnd {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*objects list*/
.containerObj {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--bs-border-color);
  cursor: pointer;
  margin-bottom: 1rem;
}

.emptyMessage {
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
}

.headObj {
  width: 20%;
  text-align: center;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  font-weight: 600;
  color: var(--bs-emphasis-color);
  /* border-right: 1px solid var(--bs-border-color); */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bodyObj {
  width: 80%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.2rem;
  color: #919599;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footerObj {
  width: 20%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.2rem;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}

.footerTag {
  text-align: center;
  padding: 4px;
  border-radius: 999px;
  font-size: 10px;
  font-weight: bold;
  background-color: #2dc8ee;
  color: black;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*step 3*/
.btnComboBox {
  padding: 0px;
  margin: 0px;
  margin-right: 0.5rem;
  cursor: pointer;
  color: var(--bs-emphasis-color);
}

/*step6 summary*/
.SummarySection {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  margin-left: 40px;
}

.SummarySectionTitle {
  display: flex;
  width: 150px;
  text-align: center;
  font-size: 26px;
  font-weight: 450;
  padding-bottom: 16px;
  justify-content: space-around;
  align-items: center;
  justify-items: flex-start;
}

.containerSummaryItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-items: center;
}

.checkIcon {
  display: flex;
  padding: 20px;
  padding-right: 15px;
  color: rgb(97, 153, 97);
}

.parentName {
  font-size: small;
}

.sectionIcon {
  font-size: 35px;
}

.ProgressBar {
  width: 100%;
  margin-left: 64px;
}

.ProgressBarWrapper {
  width: calc(100% - 64px);
}
