.resizeArea{
  cursor: col-resize !important;
  transition: ease-in-out 0.2s;
}

.newColumn{
 border: 2px dashed #858484;
}

.newColumn:hover{
  border-color: rgba(0,140,140,1);
  transition: ease-in-out 0.1s;
  cursor: pointer ;
}

.deleteButton{
  cursor: pointer;
  transition: ease-in-out 0.1s;
}

.deleteButton:hover{
  color: var(--bs-emphasis-color) !important;
}

.columnSizeShortcutOption{
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) ;
}

.columnSizeShortcutOption:hover{
  border-color: rgba(0,140,140,1) !important;
  cursor: pointer !important;
  transform: scale(1.08);
  transition: ease-in-out 0.1s;
}

.columnSizeShortcutOption:hover .shortcutColumn{
  background-color: rgba(0,140,140,1) !important;
  color: rgba(0,140,140,1) !important;
}

.resetButton{
   color: #008c8c;
}

.resetButton:hover{
  color: #02bebe;
}