.ToggleButton {
  padding-left: 4px;
}

.DropdownItem {
  color: black !important;
  background-color: white !important;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.DropdownItem:hover {
  color: black !important;
  background-color: #e9ecef !important;
}

.DropdownItemSelected {
  color: black !important;
  background-color: #dee2e6 !important;
}

.DropdownItemList {
  max-height: 150px;
  overflow-y: scroll;
}

.DropdownItem:not(:last-child) {
  border-bottom: 1px solid #dee2e6;
}

.FilterInput {
  margin: 4px 16px;
}

.NewItemButton {
  margin: 8px 16px;
}

.PageRouteName {
  color: #868e96;
}

.HiddenFilterParameter {
  display: none;
}

.ViewType {
  padding-left: 16px;
  color: #868e96;
}
.hidingName {
}

@media only screen and (max-width: 730px) {
  .hidingName {
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    max-width: 80px;
  }
}
