.container > div {
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
}

.tabsWrapper {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  background: #e0e0e0;
  padding: 8px 32px;
  opacity: 0.9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.discussionsTab {
  max-height: 100%;
  overflow: auto;
  padding: 0;
}

.appIcon {
  width: 200px;
  height: 120px;
  margin-right: 0.5rem;
  border-radius: 5px;
  background-color: chocolate;
}

.description {
  word-break: break-all;
}

.name {
  word-break: break-all;
}

@media only screen and (max-width: 480px) {
  .container {
    width: auto;
    height: auto;
  }
}
