.columnModal {
  position: absolute;
  left: 0;
  top: 0;
  width: 525px;
  height: auto;
  transition: ease-in-out 0.2s;
  background-color: #384854;
  border: 2px solid #bcc5cf;
  box-shadow: 0px 7px 15px -2px rgba(111, 111, 111, 0.57);
  z-index: 2;
  transform-origin: 0 0;
}

.columnContainer {
  padding: 12px;
  cursor: default;
}

.columnTable {
  margin: 15px 0;
  width: 100%;
}

.columnRow {
  padding: 2px 4px;
  padding-left: 16px;
  transition: opacity ease-in-out 0.2s;
  opacity: 1;
}

.columnRow:hover {
  background-color: rgba(255, 255, 255, 0.3);
  cursor: default;
}

.columnTableHeader {
  color: white;
}

.columnTableHeader th {
  padding-bottom: 16px;
  padding-left: 16px;
}

.columnRow input {
  border: 1px solid #e8e8e8;
  width: 100%;
  color: white;
}

.columnRow td {
  background-color: rgba(0, 0, 0, 0) !important;
  padding: 2px 4px;
  vertical-align: middle;
  color: white;
}

.columnRow td:first-child {
  padding-left: 16px;
}

.columnName {
  width: 200px;
}

.description {
  width: auto;
}

.columnType {
  width: 150px;
}

.selectedColumnRow {
  background-color: #0d6efd !important;
}

.selectedColumnRow td,
.selectedColumnRow input {
  color: #eff0f1;
}

.keys {
  width: 67px;
  vertical-align: middle;
}

.options {
  position: relative;
  vertical-align: middle;
  padding-top: 3px;
  width: 24px;
}

.optionsContainer {
  position: absolute;
  left: -135px;
  top: 0;
  border-radius: 5px;
  z-index: 2;
}

.optionsGroup {
  min-width: 150px;
  padding: 0.5rem;
}

.optionsItem {
  padding: 8px 16px;
  background-color: #27323a;
  cursor: pointer;
  user-select: none;
}

.optionsItem:hover {
  filter: brightness(1.2);
}

.separator {
  border: solid;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-color: currentcolor;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 0 0 1px 0;
  margin: 4px var(--m-r-pk-separator, 0) 4px var(--m-l-pk-separator, 0);
  min-height: 1px;
  border-bottom-color: var(--color-low-contrast);
  color: white;
}

.keyIcon {
  font-size: 1rem;
  margin: 0;
}

.primaryKey {
  color: yellow;
}

.foreignKey {
  color: rgb(248, 248, 248);
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  user-select: none;
  /* border: 1px solid red; */
}

.tableHeader h5,
.tableHeader p {
  display: inline;
  margin: 0;
}

tr td input {
  padding: 6px 8px;
  background-color: #1e2a34;
  border-radius: 4px;
}

.typesSelect {
  background-color: #1e2a34;
  color: #eff0f1;
}

.typesSelect:focus {
  background-color: #1e2a34;
  color: #eff0f1;
}

.typesSelect:disabled {
  background-color: #1e2a34;
}

.editIcon {
  margin-right: 0.5rem;
}

.scrollDiv {
  overflow-x: hidden;
  overflow-y: auto;
  height: 324px;
  width: 484px;
}
