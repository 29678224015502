/* Function editor dialog */
.MainWrapperForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 850px;
  overflow-y: hidden;
}

.Gap {
  margin-bottom: 16px !important;
}

.TabsWrapper {
  margin: 16px;
}

.TabWrapper {
  padding: 8px !important;
  display: flex;
  flex-direction: column;
  height: 465px;
  overflow-x: hidden;
}

@media only screen and (max-device-width: 400px) {
  .TabWrapper {
    padding: 8px !important;
    display: flex;
    flex-direction: column;
    height: 360px;
    overflow-x: hidden;
  }
}

/* Table */
.TableWrapper {
  margin-bottom: 8px;
}

.TableTitle {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Table td,
.Table th {
  font-size: 12;
  text-align: center;
}

/* Logic tab */
.ExamplesContent {
  display: flex;
  justify-content: space-between;
  color: white;
  font-weight: 400;
  font-size: 14px;
  padding: 16px;
  border-radius: 5px;
  background-color: #333333;
}

.LogicOutputBuilderButton {
  display: flex;
  align-self: flex-end;
}

.Parameter {
  cursor: pointer;
}

.Parameter:hover {
  background-color: #e9ecef;
}

.Call {
  cursor: pointer;
}

.Call:hover {
  background-color: #e9ecef;
}

.Variable {
  cursor: pointer;
}

.Variable:hover {
  background-color: #e9ecef;
}
/* Other tab */
.OtherCheckboxes {
  margin-top: 8px;
}

/* Examples tab */
.ExamplesWrapper {
  margin-top: 8px;
  margin-bottom: 24px;
}

.ExamplesTitle {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}

.ExamplesContent {
  color: white;
  font-weight: 400;
  font-size: 14px;
  padding: 16px;
  border-radius: 5px;
  background-color: #333333;
}

.errorMsg {
  color: #d9534f;
  font-weight: 600;
}
