.preview {
  position: absolute;
  width: 194px;
  background: #212529;
  border-radius: 0.8rem;
  z-index: 3;
  color: #fff;
  font-size: 11px;
  pointer-events: none;
}

.preview > img {
  border-radius: 0.8rem;
}

.cardContainer {
  cursor: grab;
  background: #29a19c;
  border: none;
  font-family: monospace;
  border-left: 4px solid #a3f7bf;
  color: #ecebeb;
  transition: cubic-bezier(1, -1.69, 0.27, 1.55) 0.1s;
}

.cardContainer:hover {
  transform: translate(0.2rem);
}

.searchInput:focus ~ .searchButton {
  background: #435055;
  filter: brightness(1.2);
  border-color: #29a19c;
  outline: 0;
  color: #29a19c;
}

.searchButton {
  background-color: #435055;
  color: #b4b4b4;
  border-color: #a3f7bf;
}

.customComponentsTitle {
  font-weight: 400;
  margin-top: 16px;
  padding-bottom: 4px;
  padding-left: 4px;
}

/* Collpse custom components header */
.hiddenIcon {
  display: none;
}

.viewHeaderName {
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  padding-left: 8px;
}

.groupHeaderName {
  font-size: 1rem;
  font-weight: 200;
  padding-left: 8px;
}

.viewHeaderNewView {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedView {
  color: #1c7ed6;
}

.viewHeader {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.viewListItem {
  cursor: pointer;
}

.closeColapseIcon {
  color: #b6b6b6;
  cursor: pointer;
}

.closeColapseIcon:hover {
  color: #767575;
}

.sectionsWrapper {
  height: calc(100% - 181px);
  display: flex;
  flex-direction: column;
}

.componentsWrapper {
  height: 100%;
  overflow: hidden;
  padding-bottom: 5px;
}

.viewHeaderCollapse {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: default;
}

.componentsContainer {
  height: 97%;
  overflow-y: auto;
  padding-right: 1rem !important;
  padding-left: 0.5rem !important;
}

.layersContainer {
  flex: 1;
  overflow: hidden;
}

.layersContent {
  height: calc(100% - 40px);
  overflow-y: auto;
  padding: 0 0 4rem;
  margin-top: 1rem;
  font-size: 0.8rem;
  font-size: 0.8rem;
}

.sectionResizer {
  height: 2px;
  width: 100%;
  background-color: rgb(84, 84, 84);
  opacity: 40%;
  cursor: row-resize;
  margin-top: 8px;
  margin-bottom: 16px;
}

.searchIcon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.CustomDropdownMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.componentItemContainer:hover .componentItem {
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
  cursor: grab;
}

.moreButton {
  height: '16px';
}

.moreButton:hover {
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
  cursor: pointer;
}

.componentItemName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
