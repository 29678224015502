.sidebarContainer {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  width: 100%;
  gap: 10px;
}

.button {
  /* width: 85%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sidebarHeader {
  padding: 1rem;
  color: #333;
}

.topContent {
  display: flex;
  align-items: center;
  justify-content: left;
}

.icon {
  padding: 0.5rem;
  font-size: 3rem;
  margin-right: 0.5rem;
}

.title {
}

.bottomContent {
}

.description {
}

.importButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
