.modalControlContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  overflow-x: hidden;
  justify-content: space-between;
  flex-direction: column;
}

.sizeTypeItem {
  display: flex;
  flex-direction: column;
  width: 20%;
  font-size: 0.85rem;
  align-items: center;
  color: var(--bs-tertiary-color) !important;
}

.sizeTypeItem:hover {
  color: var(--bs-primary-color) !important;
  cursor: pointer;
}

/* .sizeTypeItem:hover .modalContent {
  background-color: (var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
} */

.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(156, 156, 156);
  width: 100%;
  height: 48px;
  flex-direction: column;
}

.modalContainer:hover {
  background-color: var(--bs-tertiary-color) !important;
}

.modalControlContent {
  display: flex;
  justify-content: space-between;
}

.modalContent {
  width: 35%;
  height: 50%;
}
