.componentHeader {
  color: #5e5e5e;
}

.componentIcon {
  padding: 0.5rem;
  font-size: 2rem;
  margin-right: 0.5rem;
}

.componentType {
  font-size: 1.1rem;
  font-weight: bold;
}

.controlList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: calc(100% - 143px);
  overflow-y: auto;
  overflow-x: hidden;
}
