.overflowItemWrapper {
  height: 100px;
  margin-right: 0.5rem;
}

.overflowItemWrapper:last-child {
  margin-right: 0;
}

.overflowItem {
  width: 56px;
  height: 64px;
  position: relative;
  display: flex;
  padding: 10%;
  padding-right: 0;
  cursor: pointer;
}

.overflowContent {
  width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
}

.hidden {
  overflow: hidden;
}

.scroll {
  overflow: hidden;
}

.sampleColors {
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.sampleContent1 {
  position: absolute;
  height: 10%;
  width: 80%;
  top: 7px;
}

.sampleContent2 {
  position: absolute;
  height: 32%;
  width: 80%;
  top: 20px;
}

.sampleContent3 {
  position: absolute;
  height: 22%;
  width: 80%;
  top: 46px;
}

.scrollBar {
  height: 80%;
  background-color: rgb(106, 106, 245);
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
  width: 8%;
  position: absolute;
  margin-left: 10px;
  top: 6px;
  right: 10px;
}

.scrollBarScroll {
  height: 70%;
  background-color: rgb(25, 25, 248);
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;

  width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
}

.itemList {
  top: 8px;
  font-size: 0.875rem;
  overflow-x: auto;
}

.scroll:hover .scrollBarScroll {
  animation-name: scrollAnimation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.scroll:hover .overflowContent {
  animation-name: scrollContentAnimation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.overflowItem:hover .sampleColors {
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.overflowItemWrapper:hover label {
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.hidden .sampleContent1,
.hidden .sampleContent2,
.hidden .sampleContent3 {
  width: 100%;
}

.hidden .scrollBar {
  opacity: 0;
}

.auto {
  overflow: hidden;
}

.auto .sampleContent1 {
  width: 100%;
}

.auto .sampleContent1,
.auto .sampleContent2 {
  width: 100%;
}

.auto .scrollBar,
.auto .sampleContent3 {
  opacity: 0;
}

.auto:hover .sampleContent1,
.auto:hover .sampleContent2,
.auto:hover .sampleContent3 {
  animation-name: autoScrollWidthAnimation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.auto:hover .scrollBar,
.auto:hover .scrollBarScroll {
  animation-name: autoScrollHideAnimation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.auto:hover .scrollBarScroll {
  animation-name: autoScrollBarMoveAnimation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.auto:hover .sampleContent3 {
  animation-name: autoScrollHideAnimation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.auto:hover .overflowContent {
  animation-name: autoScrollContentMoveAnimation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.horizontal {
  transform: rotate(270deg) scaleX(-1);
}

.orientationWrapper {
  transition: 0.2s;
}

.overflowItemLabel {
  max-width: 56px;
  overflow: hidden;
}

@keyframes scrollAnimation {
  0% {
    top: 0%;
  }
  25% {
    top: 15%;
  }
  50% {
    top: 30%;
  }
  75% {
    top: 0%;
  }
  100% {
    top: 0%;
  }
}

@keyframes scrollContentAnimation {
  0% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-15%);
  }
  50% {
    transform: translateY(-30%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes autoScrollContentMoveAnimation {
  0% {
    transform: translateY(0%);
  }
  10% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-30%);
  }
  75% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes autoScrollWidthAnimation {
  0% {
    width: 100%;
  }
  25% {
    width: 80%;
  }
  50% {
    width: 80%;
  }
  75% {
    width: 80%;
  }
  100% {
    width: 100%;
  }
}

@keyframes autoScrollHideAnimation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  50% {
    opacity: 100;
  }
  75% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}

@keyframes autoScrollBarMoveAnimation {
  0% {
    top: 30%;
  }
  10% {
    top: 0;
  }
  75% {
    top: 30%;
  }
  100% {
    top: 30%;
  }
}
