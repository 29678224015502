.hiddenIcon {
  display: none;
}

.viewHeaderName {
  font-weight: 600;
  padding-left: 8px;
}

.viewHeaderCollapse {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: default;
}

.viewHeaderNewView {
  width: 22px;
  height: 22px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CustomDropdownMenu {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedView {
  color: #1c7ed6;
}

.viewHeader {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.viewListItem {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

/* Templates */
.TemplateThumbnailWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-height: 300px;
  overflow-y: auto;
}

.TemplateThumbnail {
  cursor: pointer;
  margin-right: 16px;
  margin-bottom: 20px;
  padding: 6px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SelectedTemplate {
  background-color: #ced4da;
  color: #333333;
  font-weight: 600;
}

.ThumbnailDescription {
  margin-top: 4px;
  font-size: 12px;
  text-align: center;
  max-width: 120px;
}

.TemplateThumbnail img {
  border-radius: 5px;
  max-width: 120px;
  min-width: 120px;
  max-height: 90px;
  min-height: 90px;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.25));
}

.paneToolbarContent {
  height: calc(100% - 76px);
  max-height: 100%;
  overflow-y: auto;
}
