/* ---------- Header ---------- */
.FunctionsGroupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
}

.HeaderTitle {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 400;
  width: 250px;
}

.FunctionsGroupButtonArrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 250px;
}

.EditFunctionButton {
  white-space: nowrap;
  margin-right: 16px;
}

.NewFunctionButton {
  white-space: nowrap;
  margin-right: 2.5rem;
}

.FixedIconWidth {
  font-size: 0.7rem;
  min-width: 18px;
}

.FunctionsListWrapper {
  margin-top: 8px;
  margin-bottom: 32px;
  overflow-y: scroll;
  max-height: calc(100vh - 320px);
  padding-bottom: 20px;
}

/* ---------- Function ---------- */
.FunctionWrapper {
  cursor: pointer;
  border-radius: 5px;
  padding: 8px;
}

.FunctionWrapper:not(:last-child) {
  margin-bottom: 16px;
}

.FunctionDescription {
  font-family: SourceCodePro;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  line-height: 16px;
}

.Badge {
  font-family: SourceCodePro;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  width: 100%;
  font-size: 1rem;
  padding: 2px 16px;
  margin-right: 8px;
  border-radius: 5px;
  white-space: break-spaces;
}

.BadgeBackground {
}

.BadgeFunction {
  text-align: center;
  padding: 3px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
  background-color: #2dc8ee;
  color: black;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CodeFunctionDefinition {
  white-space: pre;
  background-color: transparent;
  display: flex;
  width: 100%;
}

.Details {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2px;
}

.FunctionComment {
  display: flex;
  flex-direction: row;
  margin-left: 16px;
}

.CommentSlashes {
  color: #82c91e;
  margin-right: 6px;
  font-size: 12px;
}

.FunctionWrapperNew {
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
}

.FunctionWrapperNew:not(:last-child) {
  margin-bottom: 16px;
}

.FunctionWrapperNew:hover {
  background-color: rgba(77, 86, 95, 0.5) !important;
}

.FunctionDescription {
  font-family: SourceCodePro;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 16px;
}

/* .FunctionDescription:hover {
  background-color: rgba(77, 86, 95, 0.5) !important;
} */

.functionInside:hover {
  background-color: rgba(77, 86, 95, 0.5) !important;
}

.Badge {
  font-family: SourceCodePro;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  width: 100%;
  font-size: 1rem;
  margin-right: 8px;
  border-radius: 5px;
  padding: 0;
  white-space: break-spaces;
}

.BadgeBackground {
  /* background-color: #1c2025 !important; */
}

.wrapperIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.nativeBadge {
  text-align: center;
  padding: 3px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
  background-color: #2dc8ee;
  color: black;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemIcons {
  background-color: #9fa6b2;
  padding: 7px 10px;
  border-radius: 8px;
  cursor: pointer;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdownItem {
  max-width: 224px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.itemIcons:hover {
  color: #fff !important;
}
