.EditorPanelRoot {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 4fr;
  grid-template-areas: 'signature signature' 'parameters options';
}

.ReturnTypeAndName {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 30%;
  height: 13.5rem;
  padding-right: 1rem;
  grid-area: signature;
  grid-template-columns: 1fr; /* Only one column */
  gap: 10px; /* Gap between grid items */
  /* border-right: var(--bs-border-width) solid var(--bs-border-color); */
  margin-left: 3rem;
}

.NameEditor {
  display: flex;
  align-items: center;
}

.ReturnType {
  width: 40rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.ReturnTypeListCheck {
  padding: 0 0.75rem;
  position: relative;
  float: right;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: var(--bs-border-radius);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.Parameters {
  width: 100%;
  grid-area: parameters;
  display: flex;
  flex-direction: column;
  max-height: 25vh;
  overflow-y: auto;
}

.ParameterWrapper {
  background: transparent;
  text-align: center;
  max-height: fit-content;
  padding: 0.25rem;
}

.ParameterWrapper:focus-visible {
  outline: transparent;
}

.ParameterEditor {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ParameterEditor > input,
.ParameterEditor > select {
  width: fit-content;
}

.FixedOptions {
  margin-left: 1rem;
  grid-area: options;
}

.AddParameterButton {
  font-size: 0.5rem;
  display: flex;
  justify-content: center;
  align-self: center;
  width: fit-content;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.AddParameterButton:hover {
  scale: 125%;
  transition: scale 0.2s;
  cursor: pointer;
}

.FunctionSignatureWrapper {
  border-width: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.HeaderEditorWrapper {
  display: flex;
  flex-direction: column;
}

.EditorAndOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 10px;
}

.container {
  width: 35%;
  height: 13.5rem;
  margin-right: 3rem;
}

.optionsWrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}

.checkOption {
  display: flex;
  align-items: center;
}

.containerRelatedEntityData {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 0.8rem;
}

.functionIntoEnpointContainer {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 600;
}

.containerFunctionAreaEdit {
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
}

.containerFunctionAreaEdit > div {
  margin-right: 1rem;
}

.displayingMapping {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.select {
  border: none !important;
}

.containerIconMapping {
  display: flex;
  background-color: #1b2532;
  padding: 1rem;
  width: 80%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media (max-width: 1470px) {
  .EditorAndOptions {
    flex-direction: column;
  }
  .container {
    width: 100%;
    height: 7rem;
    margin-top: 2rem;
  }
  .functionIntoEnpointContainer {
    gap: 50px;
  }
}
