.ComboboxDarkThemeVariables {
  --select-border: rgb(3, 40, 48);
  --select-background: rgb(3, 40, 48);
  --select-foreground: rgb(13, 110, 253);

  --select-color-active: rgb(13, 110, 253);
  --select-color-active-background: rgb(5, 60, 72);
  --select-color-inactive: rgb(13, 110, 253);

  --select-secondary-color-active: rgb(61, 135, 247);
  --select-secondary-color-inactive: rgb(61, 135, 247);

  --select-icon-color: #9ca3af;
  --select-checkmark-icon: teal;
}

.ComboboxLightThemeVariables {
  --select-border: white;
  --select-background: white;
  --select-foreground: #111827;

  --select-color-active: black;
  --select-color-active-background: rgb(213, 213, 213);
  --select-color-inactive: black;

  --select-secondary-color-active: rgb(49, 49, 49);
  --select-secondary-color-inactive: rgb(49, 49, 49);

  --select-icon-color: #9ca3af;
  --select-checkmark-icon: teal;
}

.ComboboxWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Combobox {
  position: relative;
  margin-right: 0.5rem;
}

.ComboboxLabel {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

.ComboboxInputAndButton {
  overflow: hidden;
  position: relative;
  border-radius: 0.5rem;
  width: 100%;
  text-align: left;
  background-color: var(--select-background);
  cursor: default;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.ComboboxInput {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 2.5rem;
  border-style: none;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.25rem;
  color: var(--select-foreground);
  background-color: var(--select-background);
}

.ComboboxInput:focus {
  outline: none;
}

.ComboboxButton {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding-right: 0.5rem;
  align-items: center;
}

.ComboboxButton {
  background-color: var(--select-background);
  border: var(--select-border);
}

.ComboboxButtonIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--select-icon-color);
  padding-left: 0;
}

.ComboboxOptions {
  z-index: 999;
  overflow: auto;
  position: absolute;
  padding-top: 0.25rem;
  padding-bottom: 0.75rem;
  margin-top: 0.25rem;
  border-radius: 0.375rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  background-color: var(--select-background);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  max-height: 320px;
}

.ComboboxNothingFound {
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: var(--select-foreground);
  cursor: default;
  user-select: none;
}

.ComboboxOption {
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 2.5rem;
  cursor: default;
  user-select: none;
}

.ComboboxOptionActive {
  color: var(--select-color-active);
  background-color: var(--select-color-active-background);
}

.ComboboxOptionInactive {
  color: var(--select-color-inactive);
}

.ComboboxOptionDescriptionActive {
  color: var(--select-secondary-color-active);
}

.ComboboxOptionDescriptionInactive {
  color: var(--select-secondary-color-inactive);
}

.ComboboxOptionNameSelected {
  font-weight: 500;
  color: var(--select-foreground);
}

.ComboboxOptionNameNotSelected {
  font-weight: 400;
}

.ComboboxOptionName {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.ComboboxSelectedOptionIcon {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding-left: 0.75rem;
  align-items: center;
}

.ComboboxCheckmarkIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--select-checkmark-icon);
}

@media (min-width: 640px) {
  .ComboboxOptions {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-left: 0;
  }

  .ComboboxInputAndButton {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.TransitionLeave {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.TransitionLeaveFrom {
  opacity: 1;
}

.TransitionLeaveTo {
  opacity: 0;
}

@media (min-width: 1090px) {
  .ComboboxInputAndButton {
    min-width: 360px;
  }
}

.ComboboxDarkThemeVariables {
  --select-border: rgb(3, 40, 48);
  --select-background: rgb(3, 40, 48);
  --select-foreground: rgb(13, 110, 253);

  --select-color-active: rgb(13, 110, 253);
  --select-color-active-background: rgb(5, 60, 72);
  --select-color-inactive: rgb(13, 110, 253);

  --select-secondary-color-active: rgb(61, 135, 247);
  --select-secondary-color-inactive: rgb(61, 135, 247);

  --select-icon-color: #9ca3af;
  --select-checkmark-icon: teal;
}

.ComboboxLightThemeVariables {
  --select-border: white;
  --select-background: white;
  --select-foreground: #111827;

  --select-color-active: black;
  --select-color-active-background: rgb(213, 213, 213);
  --select-color-inactive: black;

  --select-secondary-color-active: rgb(49, 49, 49);
  --select-secondary-color-inactive: rgb(49, 49, 49);

  --select-icon-color: #9ca3af;
  --select-checkmark-icon: teal;
}

.ComboboxWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Combobox {
  position: relative;
  margin-right: 0.5rem;
}

.ComboboxLabel {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

.ComboboxInputAndButton {
  overflow: hidden;
  position: relative;
  border-radius: 0.5rem;
  width: 100%;
  text-align: left;
  background-color: var(--select-background);
  cursor: default;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.ComboboxInput {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 2.5rem;
  border-style: none;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.25rem;
  color: var(--select-foreground);
  background-color: var(--select-background);
}

.ComboboxInput:focus {
  outline: none;
}

.ComboboxButton {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding-right: 0.5rem;
  align-items: center;
}

.ComboboxButton {
  background-color: var(--select-background);
  border: var(--select-border);
}

.ComboboxButtonIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--select-icon-color);
  padding-left: 0;
}

.ComboboxOptions {
  z-index: 999;
  overflow: auto;
  position: absolute;
  padding-top: 0.25rem;
  padding-bottom: 0.75rem;
  margin-top: 0.25rem;
  border-radius: 0.375rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  background-color: var(--select-background);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  max-height: 320px;
}

.ComboboxNothingFound {
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: var(--select-foreground);
  cursor: default;
  user-select: none;
}

.ComboboxOption {
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 2.5rem;
  cursor: default;
  user-select: none;
}

.ComboboxOptionActive {
  color: var(--select-color-active);
  background-color: var(--select-color-active-background);
}

.ComboboxOptionInactive {
  color: var(--select-color-inactive);
}

.ComboboxOptionDescriptionActive {
  color: var(--select-secondary-color-active);
}

.ComboboxOptionDescriptionInactive {
  color: var(--select-secondary-color-inactive);
}

.ComboboxOptionNameSelected {
  font-weight: 500;
  color: var(--select-foreground);
}

.ComboboxOptionNameNotSelected {
  font-weight: 400;
}

.ComboboxOptionName {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.ComboboxSelectedOptionIcon {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding-left: 0.75rem;
  align-items: center;
}

.ComboboxCheckmarkIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--select-checkmark-icon);
}

@media (min-width: 640px) {
  .ComboboxOptions {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-left: 0;
  }

  .ComboboxInputAndButton {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.TransitionLeave {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.TransitionLeaveFrom {
  opacity: 1;
}

.TransitionLeaveTo {
  opacity: 0;
}

@media (min-width: 1090px) {
  .ComboboxInputAndButton {
    min-width: 360px;
  }
}
