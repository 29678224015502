.tabsWrapper {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  background: #e0e0e0;
  padding: 8px 32px;
  opacity: 0.9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.moduleListWrapper {
  background: white;
  padding: 8px 32px;
  opacity: 0.9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.searchListHeader {
  display: flex;
  justify-content: space-between;
}

.searchVersionHeader {
  display: flex;
  align-items: center;
  gap: 8px;
}

.moduleList {
  border: 1px solid #333;
  margin-top: 8px;
  padding: 8px;
}

.moduleInfo {
  display: flex;
  align-items: center;
  min-width: calc(100% - 105px - 50px);
  gap: 16px;
  height: 100%;
}

.OverflowEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.editModalButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.moduleButtons {
  display: flex;
  align-items: center;
}

.moduleButtons .moduleActionButton:not(:last-child) {
  margin-right: 4px;
}

ul {
  list-style-type: none;
}

.disabledRow {
  opacity: 0.5;
}

.ModuleItem {
  display: flex;
  justify-content: space-between;
  padding: 0 16px 0 24px;
  height: 60px;
  align-items: center;
  border-color: transparent;
}

.ModuleItem:hover {
  cursor: pointer;
}
