.newOrgModalHeader {
  border-radius: 0;
}

.newOrgModalTitle {
  font-size: 1.25rem;
}

.dialogSaveButton {
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.ButtonSpinner {
  margin-right: 10px;
}

.DisabledButtonSpinner {
  display: none;
}

.errorMessage {
  padding: 0;
  margin: 0;
  color: #d9534f;
  font-weight: bold;
}
/* @media (min-width: 450px) {
  .col-sm-10 {
    flex: 0 0 auto;
    width: 100%;
  }
} */
.dangerZoneContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
}

.dangerZoneLabels {
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 0.5rem 0;
}

.dangerZoneTitle {
  font-weight: 500;
  padding-top: 0.5rem;
}

.dangerZoneDescription {
  font-size: 0.8rem;
}
