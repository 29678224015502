.container {
  display: flex;
  align-items: center;
}

.paragraph {
  padding: 0px;
  margin: 0px;
  margin-right: 0.5rem;
}
