.mainContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.inputsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;
  background-color: rgba(241, 245, 249, 0.9);
  border-radius: 3px;
  padding: 3px;
}

.containerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 25px;
  color: black;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px;
}

.containerBtn:hover {
  background-color: #ffffff;
  cursor: pointer;
}

.containerBtnClicked {
  background-color: #ffffff;
  cursor: pointer;
}

.iconOne {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-left: 2px solid #333;
  border-top: 2px solid #333;
}

.iconTwo {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-top-left-radius: 10px;
  border-left: 2px solid #333;
  border-top: 2px solid #333;
}

.iconThree {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-top-left-radius: 80%;
  border-left: 2px solid #333;
  border-top: 2px solid #333;
}
