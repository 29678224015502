.MaxText {
    display: block;
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ActionInputLabel {
    margin-bottom: 0;
    text-align: start;
    font-size: 0.9rem;
}

.CustomQueryInputsWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
    margin-top: 1rem;
    height: auto;
    min-height: 150px;
}

.CustomQueryWrapper {
    box-shadow: 0 0 2px 2px #333;
    width: 680px;
    height: 150px;
}

.QueryVarsEditor {
    display: flex;
    flex-direction: column;
    padding-left: 3px;
    width: 40%;
    height: min-content;
}

@media screen AND (max-width: 1720px) {
    .CustomQueryWrapper {
        width: 580px;
    }
}

@media screen AND (max-width: 1620px) {
    .CustomQueryWrapper {
        width: 480px;
    }
}


@media screen AND (max-width: 1520px) {
    .ActionInputLabel {
        display: none;
    }

    .CustomQueryInputsWrapper {
        flex-direction: column;
        justify-content: start;
        flex: 1;
    }

    .CustomQueryWrapper {
        width: 800px;
    }

    .QueryVarsEditor {
        flex-direction: row;
        gap: 1rem;
        width: 100%;
    }
}

@media screen AND (max-width: 1400px) {
    .CustomQueryWrapper {
        width: 700px;
    }
}

@media screen AND (max-width: 1300px) {
    .CustomQueryWrapper {
        width: 600px;
    }
}

@media screen AND (max-width: 1200px) {
    .CustomQueryWrapper {
        width: 500px;
    }

    .QueryVarsEditor {
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }
}

@media screen AND (max-width: 1100px) {
    .CustomQueryWrapper {
        width: 400px;
    }
}
