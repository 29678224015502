.button {
  padding: 0.1rem 0.5rem !important;
  font-size: medium;
  height: 2rem;
}

.icons {
  padding: 0px;
  height: 1.5rem;
}
