/* ---------- Header ---------- */
.TagHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
}

/* Header title */
/* Width values synced with EndpointDescription */
.HeaderTitle {
  display: inline-block;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 400;
  max-width: 150px;
  margin-right: 1rem;
  white-space: nowrap;
  overflow: hidden; /*avoid the item flow out the div*/
  text-overflow: ellipsis;
}

.containerNamePath {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
}

/* Header roles */
/* .HeaderRoles {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
} */

/* Width values synced with HeaderRoleCheckbox */
.HeaderRole {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  width: 75px;
  min-width: 75px;
  max-width: 75px;
}

/* Header new endpoint button + arrow */
/* Width values synced with EndpointToggle */
.TagButtonArrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 250px;
}

.NewEndpointButton {
  white-space: nowrap;
  margin-right: 2.5rem;
}

.EditTagButton {
  white-space: nowrap;
  margin-right: 16px;
}

/* Fix icon width: icons change and have different dimensions */
.FixedIconWidth {
  font-size: 0.7rem;
  min-width: 18px;
}

/* Endpoints list */
.EndpointsListWrapper {
  margin-top: 8px;
  margin-bottom: 32px;
  overflow-y: scroll;
  max-height: calc(100vh - 370px);
  padding-bottom: 20px;
}

/* ---------- Endpoint ---------- */
.EndpointWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 6px;
  padding: 8px 16px;
}

.EndpointWrapper:hover {
  background-color: rgba(77, 86, 95, 0.5) !important;
  border-color: rgba(77, 86, 95, 0.5) !important;
}

.EndpointWrapper:not(:last-child) {
  margin-bottom: 16px;
}

.PostBackgroundColor {
  background-color: #198754 !important;
}

.GetBackgroundColor {
  background-color: #0dcaf0 !important;
}

.PutBackgroundColor {
  background-color: #ffc107 !important;
}

.DeleteBackgroundColor {
  background-color: #dc3545 !important;
}

.PatchBackgroundColor {
  background-color: #ba38e8 !important;
}

/* Endpoint description */
/* Width values synced with HeaderTitle */
.EndpointDescription {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 'fit-content';
  line-height: 16px;
}

/* Eliminate EndpointDescription width limit since there is nothing on the right */
.EndpointDescriptionWithoutRolesSection {
  width: 100%;
}

.Badge {
  color: white;
  min-width: 75px;
  height: 28px;
  font-size: 12px;
  font-weight: 700;
  padding: 8px 16px;
  margin-right: 8px;
  border-radius: 5px;
}

.UrlRequest {
  display: flex;
  min-width: 0;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 600;
  font-size: 16px;
  margin-right: 16px;
  white-space: nowrap;
}

.UrlRequest > p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  width: 700px;
  gap: 5px;
}

.wrapMethodAndPath {
  display: flex;
  width: 50%;
}

.Details {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: bold;
  width: 50%;
  gap: 0.5rem;
  padding-left: 1.5rem;
}

.Details > p {
  max-width: calc(100% - 70px);
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Roles checkboxes */
.EndpointRolesSelection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
}

/* Width values synced with HeaderRole */
.HeaderRoleCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  min-width: 75px;
  max-width: 75px;
}

.HeaderRoleCheckbox input {
  width: 16px;
  height: 16px;
}

/* Toggle */
/* Width values synced with TagButtonArrow */
.EndpointToggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.CodePreviewBtn {
  /* background-color: #9fa6b2; */
  padding: 7px 10px;
  border-radius: 8px;
  cursor: pointer;
  height: 35px;
  width: 35px;
}

.CodePreviewBtn:hover {
  color: #fff !important;
}

@media (max-width: 500px) {
  .EndpointWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .Details {
    justify-content: flex-start;
  }

  .wrapMethodAndPath {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 767px) {
  .wrapMethodAndPath {
    width: 55%;
  }
}

@media (max-width: 550px) {
  .wrapMethodAndPath {
    width: 40%;
  }
}
